import React, { useEffect, useRef } from 'react';

function ParseString({ htmlString }) {
  const containerRef = useRef(null);

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    
    // Clear the container before appending the new content
    containerRef.current.innerHTML = '';

    // Append the parsed HTML's inner content to the container
    containerRef.current.innerHTML = doc.body.innerHTML;
  }, [htmlString]);

  return (
    <div className="flex text-xs h-[100%]" ref={containerRef} />
  );
}

export default ParseString;
