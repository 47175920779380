import { Box, Typography, Icon } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon';
import './Home.css'
import LogoSvg  from '../../resources/svg/Color logo with background.svg';
import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';
import Content from '../content/courses/Content';
import Blogs from '../content/blogs/Blogs'
import Learnings from '../content/learning/Learnings'
import Slider from './Slider';
import HomeImage1 from './HomeImage1';
import ContactUs from '../contactus/ContactUs';


export default function Home() {
    return (
        <Box className="main-container">
            {window.location.href.toLowerCase().includes("home") && <HomeImage1/>}
              <Content/>
             <Learnings/>
             <Blogs/> 
             <ContactUs/>
             <Footer/>
        </Box>
    );
}

