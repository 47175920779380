import { Box } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from '../../../App';
import CourseDetails from '../../common/courses/CourseDetailsEdit';
import CourseLhn from '../../common/courses/CourseLhnEdit';
import { fetchData, getData } from '../../reusables/ApiCalls';
import { decodeValue } from '../../reusables/Common';
import useApiCall from '../../reusables/useApiCall';
import CodeEditor from '../../common/codeEditor/codeEditor';
import ParseString from '../../../Reusables/ParseString'
import Quote from '../../../Reusables/Quote';
import './CourseDetail.css';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export default function Course(props) {
    const [topicContent, setTopicContent] = React.useState([])
    const [selectedTopic, setSelectedTopic] = React.useState(0)
    const myContext = useContext(Context)
    const params = useParams()
    let course_id = decodeValue(params.course_id)
    const { data, loading, error, setRequest } = useApiCall({ method: 'get', payload: [], url: 'http://localhost:3223/course/content?course_id=' + course_id });

    const handleClick = (item) => {
        setSelectedTopic(item.course_topic_id)
        setTopicContent(JSON.parse(item.topic_content))
    }


    function parseContentItem(item, format, index) {
        let contentText = item.detail[index].content
        if (format === "image") {
            return <div className="w-[90%] m-3"><img src={contentText} height={item.detail[index].height} width={item.detail[index].width} /></div>
        }
        else if (format === "code") {
            return <div className="w-[90%] m-3"><CodeEditor code={contentText} language={"java"} showHeader={false} /></div>
        }
        else if (format === 'quote') {
            return (<Box sx={{ display: "flex", flexDirection: "column", m: 1, border: '0px solid pink' }}>
                <Quote text={contentText} />
            </Box>)
        }
        else {
            return <div className="min-w-[100%] h-full w-full p-1 text-left"><ParseString htmlString={contentText} /></div>
        }

    }


    function contentDom(content) {
        return content.map((item) =>
            <div className="flex flex-col w-full">
                <div className="flex flex-row m-1 border  w-full">{item.format.split("_").map((format_item, index) => <div className="w-full">{parseContentItem(item, format_item, index)}</div>)}</div>
            </div>)
    }
    function getTopicContentDom() {
        return topicContent.map((item, index) =>
            <div className="w-full">
                <div className="flex p-2 bg-lime-200 font-bold">{item.sub_topic_name}</div>
                <div className="flex flex-col  w-full m-1">{contentDom(item.content)}</div>
            </div>

        )
    }
    // const handleClick = async (itemTopic) => {
    //     // const handleClick = (itemTopic) => {
    //     setTopics(itemTopic.topics)
    //     setTopicName(itemTopic.topic_name)
    //     let promises = []

    //     itemTopic.topics.forEach((item, index) => {
    //         promises.push(fetchData(`${REACT_APP_API_URL}/course/topic/lookup?topic_id= ${item.topic_id}`))
    //     })

    //     const data = await Promise.allSettled(promises)
    //     let objDict = {}
    //     data.forEach((item) => {
    //         if (item.value.length > 0) {
    //             objDict[item.value[0].topic_id] = (item.value[0].topic_content)
    //         }
    //     })

    //     setTopicContent(objDict)


    // };
    // useEffect(() => {
    //     if (course_id !== undefined) {
    //         getData(REACT_APP_API_URL + '/course/topics?course_id=' + course_id).then(res => {
    //             setCourseTopics(res.data)
    //         })
    //     }
    // }, [course_id, myContext[0].isSaved])

    console.log("inside course Details...")
    console.log(data)
    console.log(`selected topic ${selectedTopic}`)
    return (
        <div className="w-full flex flex-row">

            <div className="flex flex-col w-[25%] max-h-[90vh] overflow-auto flex-start border-r-2 border-slate-400 p-3">
                {/* <CourseLhn courseTopics={courseTopics} courseId={course_id} /> */}
                {data && data.map((item) =>
                    <div className={`flex flex-row w-full border-b-2 border-slate-400 p-1 ${item.course_topic_id===selectedTopic && "bg-green-200"}`} >
                        <button className="w-full text-left hover:bg-green-100" onClick={(e) => handleClick(item)}>{item.topic_name}</button>
                    </div>
                )}
            </div>
            <div class="flex flex-col w-[75%] max-h-[90vh] overflow-auto">
                {/* <CourseDetails topics={topics} topicContent={topicContent} topicName={topicName} courseId={course_id} /> */}
                {getTopicContentDom()}
            </div>

        </div>
    );
}


