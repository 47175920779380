import * as React from 'react';
import { Box, Typography, Icon } from '@mui/material'
import ParseString from '../../common/courses/ParseString'
import CodeDisplay from '../../../Reusables/CodeDisplay';
import Quote from '../../../Reusables/Quote'
import { useParams } from 'react-router';
import FaQuoteLeft from 'react-icons/fa'
// import {content} from './content.js'
// import './CourseDetails.css'
import { getData, fetchData } from '../../reusables/ApiCalls'
// import TopicIndex from './TopicIndex';
import { Portal } from '@mui/base';
import CodeEditor from '../../common/codeEditor/codeEditor';
import useApiCall from '../../reusables/useApiCall';
import './BlogDetails.css'

const REACT_APP_API_URL = process.env.REACT_APP_API_URL

export default function CourseDetails(props) {
  let blog_id=useParams().blog_id
  const { data, loading, error, setRequest } = useApiCall({ method: 'get', payload: [], url: `${REACT_APP_API_URL}/blog/details?blog_id=${blog_id}` });
  const [topicContent, setTopicContent] = React.useState({})
  const params = useParams()

  // useEffect(() => {
  //   try {
  //     let topic_id_list = []
  //     let objDict = {}
  //     if (params.topic_id !== undefined) {
  //       topic_id_list = [...params.topic_id.split(" ")];

  //       if (topic_id_list.length > 0) {
  //         topic_id_list.forEach((item, index) => {
  //           getData(`${REACT_APP_API_URL}/course/topic/lookup?topic_id=${item}`).then(res => {
  //             objDict[res.data[0].topic_id] = { topic_content: res.data[0].topic_content, sub_topic_name: res.data[0].sub_topic_name }
  //           })
  //         })

  //       }
  //     }
  //     setTopicContent(objDict)
  //   }
  //   catch (err) {
  //     console.log(err.message)
  //   }
  // }, [params])



  function getImageComponent(topicContentList, topic_id, contentType, indexContent, indexContentSub) {
    return (<Box sx={{ display: "flex", flexDirection: "column", border: '00px solid pink', m: 1, width: '100%', justifyContent: 'center', textAlign: 'center', }}>
      <img src={topicContentList[indexContent].detail[indexContentSub].content} id={topic_id} height={topicContentList[indexContent].detail[indexContentSub].height} width={topicContentList[indexContent].detail[indexContentSub].width} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />


    </Box>)
  }

  function renderTopicContentItem1(contentType, topic_id, indexContent, indexContentSub) {

    let topicContentCopy = JSON.parse(JSON.stringify(topicContent))

    if (Object.keys(topicContentCopy).includes(`${topic_id}`)) {
      let topicContentList = JSON.parse(topicContentCopy[topic_id].topic_content)
      let content = topicContentList[indexContent].detail[indexContentSub].content
      let height = (topicContentList[indexContent].detail[indexContentSub].height === undefined || topicContentList[indexContent].detail[indexContentSub].height === '') ? '100%' : topicContentList[indexContent].detail[indexContentSub].height
      let width = (topicContentList[indexContent].detail[indexContentSub].width === undefined || topicContentList[indexContent].detail[indexContentSub].width === '') ? '100%' : topicContentList[indexContent].detail[indexContentSub].width

      if (contentType === 'code') {
        return (<Box sx={{ display: "flex", flexDirection: "column", m: 1, border: '0px solid pink', width: { width } }}>
          {/* <CodeDisplay code={topicContentList[indexContent].detail[indexContentSub].content}/> */}
          <CodeEditor code={topicContentList[indexContent].detail[indexContentSub].content} language={topicContentList[indexContent].detail[indexContentSub].language}
            showHeader={topicContentList[indexContent].detail[indexContentSub].showHeader} />
        </Box>)
      }
      else if (contentType === 'text') {
        return (<Box sx={{ display: "flex", flexDirection: "column", m: 1, border: '0px solid pink', width: { width }, height: { height } }}>
          <div className="text-start text-xs">{<ParseString htmlString={topicContentList[indexContent].detail[indexContentSub].content} />}</div>
        </Box>)
      }
      else if (contentType === 'quote') {
        return (<Box sx={{ display: "flex", flexDirection: "column", m: 1, border: '0px solid pink', width: { width }, height: { height } }}>
          <Quote text={topicContentList[indexContent].detail[indexContentSub].content} />
        </Box>)
      }
      else {
        return getImageComponent(topicContentList, topic_id, contentType, indexContent, indexContentSub)
      }
    }

  }

  function renderTopicContentItem(text, contentType, topic_id, indexContent, indexContentSub) {
    // return <div className=" h-full pt-[200px]">{JSON.stringify(text)}</div>

    // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))

    // if (Object.keys(topicContentCopy).includes(`${topic_id}`)) {
    let topicContentList = text
    let content = topicContentList[indexContent].detail[indexContentSub].content
    let height = (topicContentList[indexContent].detail[indexContentSub].height === undefined || topicContentList[indexContent].detail[indexContentSub].height === '') ? '100%' : topicContentList[indexContent].detail[indexContentSub].height
    let width = (topicContentList[indexContent].detail[indexContentSub].width === undefined || topicContentList[indexContent].detail[indexContentSub].width === '') ? '100%' : topicContentList[indexContent].detail[indexContentSub].width

    if (contentType === 'code') {
      return (<Box sx={{ display: "flex", flexDirection: "column", m: 1, border: '0px solid pink', width: { width } }}>
        {/* <CodeDisplay code={topicContentList[indexContent].detail[indexContentSub].content}/> */}
        <CodeEditor code={topicContentList[indexContent].detail[indexContentSub].content} language={topicContentList[indexContent].detail[indexContentSub].language}
          showHeader={topicContentList[indexContent].detail[indexContentSub].showHeader} />
      </Box>)
    }
    else if (contentType === 'text') {
      return (<Box sx={{ display: "flex", flexDirection: "column", m: 1, border: '0px solid black', width: { width }, height: { height } }}>
        <div className="text-start text-xs h-full w-full">{<ParseString htmlString={topicContentList[indexContent].detail[indexContentSub].content} />}</div>
      </Box>)
    }
    else if (contentType === 'quote') {
      return (<Box sx={{ display: "flex", flexDirection: "column", m: 1, border: '0px solid pink', width: { width }, height: { height } }}>
        <Quote text={topicContentList[indexContent].detail[indexContentSub].content} />
      </Box>)
    }
    else {
      return getImageComponent(topicContentList, topic_id, contentType, indexContent, indexContentSub)
    }
    // }

  }

  function parseText(text, topic_id) {
    try {
      if (text === undefined) {
        return
      }
      let content1 = text
      let textContainer = []
      content1.forEach((item, indexContent) => {
        let isNew = item.format.includes("0")
        let arrContentKeys = item.format.split("_")
        textContainer.push(
          <Box sx="flex flex-row">
            <div name={`text_only${indexContent}`} className="w-full flex flex-row items-center">
              {arrContentKeys.map((contentType, indexContentSub) =>
                renderTopicContentItem(text, contentType, topic_id, indexContent, indexContentSub)
              )}
            </div>

          </Box>
        )

      })
      // textContainer.push(getAddNewContentControl(topic_id, index))
      return [<Box id={topic_id} sx={{ border: '0px solid red', display: "flex", flexDirection: "column", border: "0px solid red", width: "90%", padding: "1%" }}>{textContainer}</Box>]
    }
    catch (err) {
      console.log(err.message)
      // return < Box sx={{ width: "100%", textAlign: "center" }} > {text} {getAddNewContentControl(topic_id)}</Box >;
    }
  }





  return (
    <div className="flex flex-col mt-[80px] w-[80%] justify-center mx-auto ">
      
      <Box class="flex flex-col">
        {(!loading && data) && data.map((item, index) =>   
        <><div className="text-4xl pb-5 mb-10 border-b-2 border-slate-400">{item.blog_title}</div>
          <Box sx={{ display: "flex", flexDirection: "column", width: '100%' }}>
            {((JSON.parse(item.topic_content)).map((topic_content_item) => (<>
              <Box class="flex ml-2 bg-slate-50 font-bold p-2" >{topic_content_item.sub_topic_name}</Box >
              <Box class="flex text-[10px]">{data === "" || !data ? "No Content Found" : parseText((topic_content_item.content))}</Box >
            </>)))}
          </Box>
</>
        )}

      </Box>
     
    </div>


  );
}


// import { Box, Typography, Icon } from '@mui/material'
// import SvgIcon from '@mui/material/SvgIcon';
// import './Blogs.css'
// // import LogoSvg from '../../resources/svg/Color logo with background.svg'
// import Java from '../../../resources/images/java-logo.png'
// import Python from '../../../resources/images/python-logo.png'
// // import CLogo from '../../resources/images/c-logo.png'
// // import CPlusPlus from '../../resources/images/c++.png'
// import Selenium from '../../../resources/images/selenium-logo.png'
// import WebDevelopment from '../../../resources/images/web-development-logo.png'
// import Interview from '../../../resources/images/interview.png'
// import CodingProblems from '../../../resources/images/coding-problems.png'
// import QaTesting from '../../../resources/images/qa-testing.png'
// // import PracticeQuestions from '../../resources/images/practice-questions.png'
// // import SeleniumExceptions from '../../resources/images/selenium-exceptions.png'
// import Pywinauto from '../../../resources/images/pywinauto.png'
// import AutomateWisely from '../../../resources/images/automate-wisely.jpg'
// // import MenuBar from '../common/menu/Menubar'
// // import PersonIcon from '@mui/icons-material/Person';
// import BlogCard from '../../common/content/BlogCard';
// import BlogText from './BlogText'
// import useApiCall from '../../reusables/useApiCall';
// import { useState } from 'react';


// const text = `Selenium is a powerful tool for automating web applications, but like any tool, its effectiveness depends on how it's used. In this blog post, we'll explore some best practices and tips for achieving effective test automation with Selenium. Selenium is a powerful tool for automating web applications, but like any tool, its effectiveness depends on how it's used. In this blog post, we'll explore some best practices and tips for achieving effective test automation with Selenium.`
// export default function BlogDetails() {
//   const { data, loading, error, setRequest } = useApiCall({ method: 'get', payload: [], url: REACT_APP_API_URL + '/blog/details?blog_id=1' });
//     return (
//         <Box class="flex flex-col w-[98%] mx-auto mt-[100px]">

//           Blog Details

//           {JSON.stringify(data)}


//         </Box>
//     );
// }

