import * as React from 'react';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './BlogCard.css'
import CodeDisplay from '../../../Reusables/CodeDisplay';
import ReactCodeEditor from '../../../Reusables/ReactCodeEditor';

const javaCode = `public class HelloWorld {
  public static void main(String[] args) {
      System.out.println("Hello, World!");
  }
}`
export default function BlogCard(props) {
  return (
    <Box className="flex flex-col w-[20vw] h-[50vh] border-solid border-2 border-slate-300 shadow-2xl rounded-2xl">
      <Box className="blog-image"><img src={props.image} height="100%" width="100%" /></Box>

      <Box className="blog-title">{props.title}</Box>
      <p className="text-xs max-h-[50%] h-[50%] overflow-hidden whitespace-normal text-ellipsis text-left p-1">
        {props.summary}</p>

     
      <Box className="bg-black text-white hover:bg-slate-500 mt-4"><a href={`/blog/${props.blog_id}`} target="_blank">Read more...</a></Box>


    </Box>
  );
}