import { Box, Typography, Icon, TextField } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon';
import './Header.css'
// import LogoSvg  from '../../../resources/svg/logo_black.svg'
import LogoSvg  from '../../../resources/svg/White logo - no background.svg'
// import MenuBar from '../menu/MenubarTab'
import MenuAppBar from '../menu/MenuBarConcise'
import PersonIcon from '@mui/icons-material/Person';
import { useState, useContext, useEffect } from "react";
import { getData } from '../../reusables/ApiCalls'

const REACT_APP_API_URL = process.env.REACT_APP_API_URL

let classValue=""
  if (window.location.href.toLowerCase().includes("home")) {
    classValue='header-container'
  }
  else {
    classValue='header-container course'
  }

export default function Header() {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    getData(`${REACT_APP_API_URL}/course/lookup`).then(res => {
      setCourses(res.data)
    })
  }, [])


    return (
        <Box class={classValue}>
            {/* <Box class="logo-container"><img src={LogoSvg} width='80%' height='auto' style={{display:'block',marginRight:'auto', marginLeft:'auto'}}/> </Box> */}
            <MenuAppBar courses={courses}/>
            {/* <TextField placeholder="Search..."/> */}
            {/* <Box class="avatar-container"><PersonIcon sx={{color: "white"}}/></Box> */}
        </Box>
    );
}

