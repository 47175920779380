import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import './Footer.css'
import CaiLogo from '../../../resources/svg/Black logo - no background.svg'
const Footer = () => {
  // Get the current year dynamically
  const currentYear = new Date().getFullYear();

  return (
    <Box className="flex flex-col w-[98%] max-w-[98%] overflow-hidden mx-auto h-[40vh] bg-gradient-to-b from-green-900 to-black p-2 text-white relative">

      <Box className="copyright">
        &copy; {currentYear} Coding Academy India
      </Box>



      <Box className="footer-section">
        <Box className="footer-sub">
          <Box className="footer-subheader"><b>Courses</b></Box>
          <Box className="footer-subitem">Java</Box>
          <Box className="footer-subitem">Python</Box>
          <Box className="footer-subitem">PHP</Box>
          <Box className="footer-subitem">C#</Box>
          <Box className="footer-subitem">C</Box>
          <Box className="footer-subitem">C++</Box>

        </Box>
        <Box className="footer-sub">
          <Box className="footer-subheader"><b>Learning Essentials</b></Box>
          <Box className="footer-subitem">Interview Questions</Box>
          <Box className="footer-subitem">Coding Problems</Box>
          <Box className="footer-subitem">Quiz</Box>
          <Box className="footer-subitem">Forms</Box>
        </Box>
        <Box className="footer-sub">
          <Box className="footer-subheader"><b>Social Media</b></Box>
          <Box className="footer-subitem">Twitter</Box>
          <Box className="footer-subitem">Faceboox</Box>
          <Box className="footer-subitem">Linked-in</Box>
          <Box className="footer-subitem">Youtube</Box>
        </Box>
        <Box className="footer-sub">
          <Box className="footer-subheader"><b>Information</b></Box>
          <Box className="footer-subitem">About Us</Box>
          <Box className="footer-subitem">Contact Us</Box>
          <Box className="footer-subitem">Newsletter</Box>

        </Box>
      </Box>
      <Box className="footer-logo">
        <img src={CaiLogo} className="w-[80%] h-[80%]"/>
      </Box>
    </Box>

  );
};

export default Footer;
