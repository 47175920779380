import { useState, useEffect, useRef, useContext } from 'react';
import Editor from "@monaco-editor/react";
import copy from 'copy-to-clipboard';
import { FaCopy, FaCheck } from 'react-icons/fa6'
import { MdOutlineOpenInNew } from 'react-icons/md'
import { Context } from '../../../App';

function CodeEditor(props) {
    const editorRef = useRef(null);
    const [editorHeight, setEditorHeight] = useState('40px'); // Set 
    const [isCopied, setIsCopied] = useState(false);
    const myContext = useContext(Context)
    const [, setVal] = useContext(Context)


    const options = {
        fontSize: '13px',
        minimap: {
            enabled: false, // Disable the minimap
        },
        readOnly: true, // Make the editor readonly'
        scrollBeyondLastLine: false,
        lineNumbers: "off",
        wordWrap: 'on', // 'on' enables word wrap
        wordWrapColumn: 80, // Opt
    }

    useEffect(() => {
        const contentLines = props.code.split('\n').length;
        const newHeight = contentLines * 20
        setEditorHeight(`${newHeight}px`);
    }, [props.code]);

    const handleCopyClick = () => {
        setVal(prevVal => ({ ...prevVal, "code": props.code }))
        copy(props.code); // Copy the text to the clipboard
        setIsCopied(true); // Set the state to indicate that it's copied
    };

    function getLangauage() {
        if (props.language.toLowerCase() === "python") {
            return "python3"
        }
        else if (props.language.toLowerCase() === "c++") {
            return "cpp"
        }
        else if (props.language.toLowerCase() === "c#") {
            return "csharp"
        }
        else if (props.language.toLowerCase() === "c#") {
            return "csharp"
        }
        else if (props.language.toLowerCase() === "vbn") {
            return "vb"
        }
        else {
            return props.language.toLowerCase()
        }
    }

    const handleGiveItATry = (e) => {
        setVal(prevVal => ({ ...prevVal, "code": props.code }))
        // window.location.href=`/compiler/${getLangauage(props.language.toLowerCase())}`
    }

    return (
        <div className="w-full">
            {props.showHeader===true &&
                <div className="flex w-full bg-gray-600 rounded-t-lg text-slate-300  p-1 justify-between mb-2">
                    <button onClick={handleGiveItATry} className="text-sm flex flex-row items-center">
                        Give It a Try <MdOutlineOpenInNew /></button>
                    <button className="mr-2 text-sm" onClick={handleCopyClick}>
                        {isCopied ? <span class=" flex flex-row items-center w-20">Copied <FaCheck class="ml-2" /></span> : <span class=" flex flex-row items-center w-20">Copy <FaCopy class="ml-2" /></span>}</button></div>}
            <Editor
                options={options}
                height={editorHeight}
                theme={props.theme==='light'?'vs-light':'vs-dark'}
                language={!props.language ? "javascript" : props.language.toLowerCase()}
                defaultLanguage={!props.language ? "javascript" : props.language.toLowerCase()}
                defaultValue={props.code}
            />
        </div>
    );
}

export default CodeEditor;
