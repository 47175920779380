import { Box, Typography, Icon } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon';
// import LogoSvg from '../../resources/svg/logo_black.svg';
import LogoSvg from '../../resources/svg/Color logo with background.svg';
export default function ComingSoon() {
    return (
        <div className='w-full flex flex-col justify-center items-center'>
            {/* <SvgIcon component={LogoSvg} inheritViewBox />
            <Icon sx={{ color: "green" }} fontSize="large"><LogoSvg/></Icon> */}

            <img src={LogoSvg} width="300" />
            <Typography variant="h2">Coming Soon !</Typography>
            <Typography variant="h5">Learn and Grow !!</Typography>

        </div>
    );
}

