import { useState, useEffect, useContext } from 'react';
import './CodeCompiler.css';
import Editor from "@monaco-editor/react";
import Navbar from './Navbar';
import { Button } from '@mui/base';
import Box from '@mui/material/Box'
import axios from 'axios';
import { FiSettings } from 'react-icons/fi'
import FullScreenDialog from '../dialogs/FullScreenDialog';
import HelloWorldText from '../../pages/common/content/HelloWorld';

import { Context } from '../../App';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;


function CodeCompiler(props) {
	// State variable to set users source code
	const myContext = useContext(Context)
	const [userCode, setUserCode] = useState(myContext[0].code?myContext[0].code:"");
	
	// State variable to set editors default language
	const [userLang, setUserLang] = useState("python");

	// State variable to set editors default theme
	const [userTheme, setUserTheme] = useState(true);

	// State variable to set editors default font size
	const [fontSize, setFontSize] = useState(14);

	// State variable to set users input
	const [userInput, setUserInput] = useState("");

	// State variable to set users output
	const [userOutput, setUserOutput] = useState("");
	const [dock, setDock] = useState(true);
	const [mainCss, setMainCss] = useState("main right notfull");


	// Loading state variable to show spinner
	// while fetching data
	const [loading, setLoading] = useState(false);

	const options = {
		fontSize: fontSize
	}

	function getLangauageHelloWorld() {
		if (props.language.toLowerCase().includes("python")) {
			return "python"
		}
		else if (props.language.toLowerCase() === "cpp") {
			return "c++"
		}
		else if (props.language.toLowerCase() === "csharp") {
			return "c#"
		}
	
		else {
			return props.language
		}
	}

	function getLangauageCompiler() {
		if (props.language.toLowerCase() === "javascript") {
			return "nodejs"
		}
		else if (props.language.toLowerCase() === "vb") {
			return "vbn"
		}
		else {
			return props.language.toLowerCase()
		}
	}

	function getLangauageEditor() {
		if (props.language.toLowerCase() === "vb") {
			return "vbn"
		}
		else {
			return props.language.toLowerCase()
		}
	}
	
	const executeCode = async () => {
		setLoading(true)
		let userCodeActual = userCode
		try {
			if (userCode === "") {
				if (document.referrer.includes("/home")) {
					userCodeActual = HelloWorldText[getLangauageHelloWorld()]
				}
				else {
					setUserOutput("No code to execute !!")
					setLoading(false)
					return

				}
			}
			const response = await axios.post(`${REACT_APP_API_URL}/compiler/execute/code`, {
				script: userCodeActual,
				language: getLangauageCompiler(props.language), // Change the language as needed
				versionIndex: '3',
				clientId: 'f52c9a3b13ba914921c02f4f597d32b7',
				clientSecret: 'b7ffa7dca87ae6b5d846781026c6998bd540b8e2be6561f3d7fa3e9c0417a563',
			});
			if (response.data.output) {
				setUserOutput(response.data.output.replaceAll('File "jdoodle.py",', ''));
			}
			setLoading(false)
		} catch (error) {
			setUserOutput(error.message)
			setLoading(false)
			console.log('Error executing code');
		}
	};

	// Function to clear the output screen
	function clearOutput() {
		setUserOutput("");
	}


	const handleFontSizeLess = () => {
		setFontSize(fontSize - 2)
	}

	const handleFontSizeMore = () => {
		setFontSize(fontSize + 2)
	}


	const handleViewMode = () => {
		setUserTheme(!userTheme)
	}


	const handleDock = () => {
		setDock(!dock)
	}

	function getMainCss() {
		if (dock && props.open) {
			setMainCss("main right fullscreen")
		}
		else if (dock && !props.open) {
			setMainCss("main right notfull")
		}
		else if (!dock && props.open) {
			setMainCss("main bottom fullscreen")
		}
		else if (!dock && !props.open) {
			setMainCss("main bottom notfull")
		}
	}


	useEffect(() => {
		getMainCss()
	}, [])
	return (
		<div className={userTheme ? "code-compiler-container light" : "code-compiler-container dark"}>

			<Navbar handleFontSizeLess={handleFontSizeLess} handleFontSizeMore={handleFontSizeMore} handleViewMode={handleViewMode} handleDock={handleDock}
				handleFullScreen={props.handleFullScreen}
				userTheme={userTheme}
				userLang={getLangauageEditor(props.language.toLowerCase())}
				dock={dock}
				open={props.open}
				setUserLang={setUserLang}
				setFontSize={setFontSize}
				executeCode={executeCode}
			/>
			<div className={mainCss}>
				<div className={dock ? 'left-container left-inline' : 'left-container left-bottom'} >
					<Editor
						options={options}
						height="100%"
						width="100%"
						theme={userTheme ? 'vs-light' : 'vs-dark'}
						language={props.language.toLowerCase()}
						defaultLanguage={props.language.toLowerCase()}
						defaultValue={document.referrer.includes("/home") ? HelloWorldText[getLangauageHelloWorld(props.language)] : "# Enter your code here"}
						onChange={(value) => { setUserCode(value) }}
					/>
				</div>
				<Box className={dock ? 'right-container right-inline' : 'right-container right-bottom'} sx={{ fontSize: fontSize }} >
					<Box className={dock ? 'input-box input-inline' : 'input-box input-bottom'}>
						<textarea className={userTheme ? 'code-inp light' : 'code-inp dark'} onChange=
							{(e) => setUserInput(e.target.value)}>
						</textarea>
						<div className="settings">Input</div>
					</Box>
					{loading ? (
						<Box className="spinner-box">
							Loading...
						</Box>
					) : (
						<Box className={dock ? 'output-box output-inline' : 'output-box output-bottom'}>
							<pre>{userOutput}</pre>
							<div className="settings">Output</div>
						</Box>
					)}
				</Box>
			</div>
		</div>
	);
}

export default CodeCompiler;
