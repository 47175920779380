import { Box, Typography, Icon } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon';
import './Blogs.css'
// import LogoSvg from '../../resources/svg/Color logo with background.svg'
import Java from '../../../resources/images/java-logo.png'
import Python from '../../../resources/images/python-logo.png'
// import CLogo from '../../resources/images/c-logo.png'
// import CPlusPlus from '../../resources/images/c++.png'
import Selenium from '../../../resources/images/selenium-logo.png'
import WebDevelopment from '../../../resources/images/web-development-logo.png'
import Interview from '../../../resources/images/interview.png'
import CodingProblems from '../../../resources/images/coding-problems.png'
import QaTesting from '../../../resources/images/qa-testing.png'
// import PracticeQuestions from '../../resources/images/practice-questions.png'
// import SeleniumExceptions from '../../resources/images/selenium-exceptions.png'
import Pywinauto from '../../../resources/images/pywinauto.png'
import AutomateWisely from '../../../resources/images/automate-wisely.jpg'
// import MenuBar from '../common/menu/Menubar'
// import PersonIcon from '@mui/icons-material/Person';
import BlogCard from '../../common/content/BlogCard';
import BlogText from './BlogText'
import useApiCall from '../../reusables/useApiCall';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL
const text = `Selenium is a powerful tool for automating web applications, but like any tool, its effectiveness depends on how it's used. In this blog post, we'll explore some best practices and tips for achieving effective test automation with Selenium. Selenium is a powerful tool for automating web applications, but like any tool, its effectiveness depends on how it's used. In this blog post, we'll explore some best practices and tips for achieving effective test automation with Selenium.`
export default function Blogs() {
    const { data, loading, error, setRequest } = useApiCall({ url: REACT_APP_API_URL + '/blog/list/display', method: 'get', payload: null })

    return (
        <Box class="flex flex-col w-[98%] mx-auto">

            <Box class="flex flex-col pb-4 border-solid border-2 border-slate-200 rounded-2xl">
                <Box class="blog-section-title">Blogs</Box>

                <Box class="blog-card-container">
                    {
                        data && data.map((item) =>
                            <Box className="flex justify-center m-1 w-[full] border-solid">
                                <BlogCard title={item.blog_title} summary={item.blog_summary} blog_id={item.blog_id}
                                    image={item.image_path} text={text} /></Box>)}
                    {/* <Box class="flex justify-center m-1 w-full border-solid"><BlogCard title="Continuous Integration and Selenium: Streamlining Your Test Automation Pipeline" image={Selenium} text={BlogText['coding problems']}/></Box>
                    
                    <Box class="flex justify-center m-1 w-full border-solid"><BlogCard title="10 Essential JavaScript Concepts Every Developer Should Know" image={WebDevelopment} text={BlogText['quiz']}/></Box>
                    <Box class="flex justify-center m-1 w-full border-solid"><BlogCard title="Exploring Python Libraries: A Deep Dive into NumPy and Pandas" image={Pywinauto} text={BlogText['quiz']}/></Box> */}
                </Box>
                <Box></Box>

            </Box>


        </Box>
    );
}

