import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { Box, Typography, Icon, TextField, Button } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { Container, Paper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
// import {content} from './content.js'
import './BlogDetailsEdit.css'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { fetchData, getData, deleteData, putData } from '../../reusables/ApiCalls'
import Notification from '../../../Reusables/Notification';
import { Context } from '../../../App';
import { contentType } from '../../../const/contentType';
import { contentTypeOptions } from '../../../const/contentTypeOptions';
import CodeDisplay from '../../../Reusables/CodeDisplay';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Tooltip from '@mui/material/Tooltip';
import ContentMenu from '../../../Reusables/ContentMenu';
import useApiCall from '../../reusables/useApiCall';
import { useParams } from 'react-router';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL
export default function BlogDetailsEdit(props) {
  let blog_id = useParams().blog_id
  const { data, loading, error, setRequest } = useApiCall({ method: 'get', payload: [], url: `${REACT_APP_API_URL}/blog/details?blog_id=${blog_id}` });
  const { data: dataPost, loading: loadingPost, error: errorPost, setRequest: setRequestPost } = useApiCall({ method: 'post', payload: [], url: '' });
  const [newTopicContent, setNewTopicContent] = useState([]);
  const [topics, setTopics] = useState([]);
  const [topicContent, setTopicContent] = useState([]);
  const [selectedTopicContentType, setTopicContentType] = useState({ label: "" });
  const [subTopicCounter, setSubTopicCounter] = useState(0);
  const [updated, setUpdated] = useState(true);
  const [msg, setMsg] = useState("");
  const myContext = useContext(Context)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [topic_id, setTopicId] = React.useState(0);
  const [contentIndex, setContentIndex] = React.useState(0);
  const [subIndex, setSubIndex] = React.useState(0);

  const handleMenuClick = (event, indexContent, indexMain) => {
    setTopicId(event.target.id)
    setAnchorEl(event.currentTarget);
    setContentIndex(indexContent)
    setSubIndex(indexMain)
  };


  const handleTopicTextChange = (event, indexContent, indexContentSub, indexMain) => {
    let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
    topicContentCopy[indexMain].content[indexContent].detail[indexContentSub].content = event.target.value
    // let topicContentList = JSON.parse(topicContentCopy[event.target.id])
    // topicContentList[indexContent].detail[indexContentSub].content = event.target.value
    // topicContentCopy[event.target.id] = JSON.stringify(topicContentList)
    setTopicContent(topicContentCopy)
  }

  const handleBlogTitleChange = (event) => {
    let topicsCopy = JSON.parse(JSON.stringify(topics))
    topicsCopy[0].blog_title = event.target.value
    setTopics(topicsCopy)
  }

  const handleBlogSummaryChange = (event) => {
    let topicsCopy = JSON.parse(JSON.stringify(topics))
    topicsCopy[0].blog_summary = event.target.value
    setTopics(topicsCopy)
  }

  const handleImageProps = (event, topic_id, indexContent, indexContentSub, indexMain) => {
    try {
      let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
      topicContentCopy[indexMain].content[indexContent].detail[indexContentSub][event.target.name] = event.target.value
      setTopicContent(topicContentCopy)
    } catch (err) {
      console.log(err.message)
    }
  }



  function getToolBarOptions(indexContent, name, topic_id, indexMain) {
    return (
      <Tooltip title={name}>
        <Box sx={{ display: 'flex', flexDirection: "row" }}>
          <IconButton aria-label="delete" size="small" id={topic_id} onClick={(e) => handleToolBarOption(e, indexContent, topic_id, 'delete', indexMain)}>
            <DeleteIcon fontSize="inherit" id={topic_id} onClick={(e) => handleToolBarOption(e, indexContent, topic_id, 'delete', indexMain)} />
          </IconButton>
          <IconButton aria-label="add" size="small" id={topic_id} onClick={(e) => handleToolBarOption(e, indexContent, topic_id, 'add', indexMain)}>
            <AddIcon fontSize="inherit" id={topic_id} onClick={(e) => handleToolBarOption(e, indexContent, topic_id, 'add', indexMain)} />
          </IconButton>

        </Box>
      </Tooltip>
    )
  }

  const handleSubTopicChange = (event) => {
    let index = parseInt(event.target.id)
    let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
    topicContentCopy[index].sub_topic_name = event.target.value
    setTopicContent(topicContentCopy)
  }

  const handleSave = (event) => {
    let payload = []
    topics.forEach((topic_item, index) => {
      let objDict = {}
      objDict.blog_id = topic_item.blog_id
      objDict.blog_title = topic_item.blog_title
      objDict.topic_content = JSON.parse(topic_item.topic_content)
      objDict.blog_summary = (topic_item.blog_summary)
      objDict.image_path = (topic_item.image_path)
      payload.push(objDict)
    })


    if (payload.length !== 0) {
      setRequestPost({ method: 'put', url: REACT_APP_API_URL + '/blog/details', payload: payload })
      setUpdated(!updated)
      setMsg("Saved !!")

    }

  }

  const addTopicContent = (event) => {
    setAnchorEl(null)
    setNewTopicContent([...newTopicContent, 0]);
    let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
    // let topicContentList = JSON.parse(topicContentCopy[contentIndex])
    // if (topicContentList === null) {
    //   topicContentList = []
    // }
    let iLen = topicContentCopy[subIndex].content[contentIndex].detail.length
    if (event.target.innerText.toLowerCase().includes("text")) {
      topicContentCopy[subIndex].content[contentIndex].detail.splice(iLen, 0, { type: 'text', content: 'new Text', width: '100%', height: '100%' })
      topicContentCopy[subIndex].content[contentIndex].format = topicContentCopy[subIndex].content[contentIndex].format + "_text"
      // topicContentList[contentIndex].format = topicContentList[contentIndex].format + "_text"
      // topicContentList[contentIndex].detail.push({ type: 'text', content: 'new Text', width: '100%', height: '100%' })
    }
    else if (event.target.innerText.toLowerCase().includes("image")) {
      topicContentCopy[subIndex].content[contentIndex].detail.splice(iLen, 0, { type: 'image', content: '/images/Advertisement.png', width: '100px', height: '100px' })
      topicContentCopy[subIndex].content[contentIndex].format = topicContentCopy[subIndex].content[contentIndex].format + "_image"
    }
    else if (event.target.innerText.toLowerCase().includes("code")) {
      topicContentCopy[subIndex].content[contentIndex].detail.splice(iLen, 0, { type: 'code', content: 'new Code', width: '100%', height: '100%', language: "java" })
      topicContentCopy[subIndex].content[contentIndex].format = topicContentCopy[subIndex].content[contentIndex].format + "_code"
    }
    else if (event.target.innerText.toLowerCase().includes("quote")) {
      topicContentCopy[subIndex].content[contentIndex].detail.splice(iLen, 0, { type: 'quote', content: 'new Quote', width: '100%', height: '100%' })
      topicContentCopy[subIndex].content[contentIndex].format = topicContentCopy[subIndex].content[contentIndex].format + "_quote"
    }
    else {
      setUpdated(!updated)
      setMsg("Please selected Content Type")
    }


    setTopicContent(topicContentCopy)

  }

  const addSubTopic = (event, index) => {
    let name = event.target.name
    let topicsCopy = JSON.parse(JSON.stringify(topicContent))
    topicsCopy.splice(index + 1, 0, { content: [{ format: 'text', detail: [{ type: 'text', content: 'New Content' }] }], sub_topic_name: "New Sub Topic Name" })
    // topicsCopy.push({topic_id: 0,  sub_topic_name: "New Sub Topic Name"})
    setTopicContent(topicsCopy)

    // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
    // topicContentCopy[`new${subTopicCounter}`] = JSON.stringify([
    //   {
    //     "format": "text",
    //     "detail": [
    //       {
    //         "type": "text",
    //         "content": "new Text"
    //       }
    //     ]
    //   }
    // ])
    // setTopicContent(topicContentCopy)
    // setSubTopicCounter(subTopicCounter + 1)
    setUpdated(!updated)
    setMsg("New Sub Topic Added")
  }



  const deleteSubTopic = (event, index, topic_id) => {
    let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
    topicContentCopy.splice(index, 1)
    setTopicContent(topicContentCopy)


    // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
    // delete topicContentCopy[topic_id]
    // setTopicContent(topicContentCopy)
    // deleteData(`${REACT_APP_API_URL}/course/topic/lookup?topic_id=${topic_id}`).then(res => {
    //   setUpdated(!updated)
    //   setMsg("Sub Topic deleted with topic_id" + topic_id)
    // })

  }




  const deleteSubTopicSubContent = (event, topic_id, indexContent, indexContentSub, indexMain) => {
    let topicContentCopy = JSON.parse(JSON.stringify(topicContent))

    // let topicContentList = JSON.parse(topicContentCopy[topic_id])
    // if (topicContentList === null) {
    //   topicContentList = []
    // }
    let format = topicContentCopy[indexMain].content[indexContent].format.split('_')
    format.splice(indexContentSub, 1)
    let formatUpdated = ""
    format.forEach((contentType, index) => {
      if (index === 0) {
        formatUpdated = contentType
      }
      else {
        formatUpdated = formatUpdated + '_' + contentType
      }
    })
    topicContentCopy[indexMain].content[indexContent].format = formatUpdated
    topicContentCopy[indexMain].content[indexContent].detail.splice(indexContentSub, 1)

    // topicContentCopy[topic_id] = JSON.stringify(topicContentList)
    setTopicContent(topicContentCopy)
  }

  useEffect(() => {
    if (!loading && data) {
      setTopicContent(JSON.parse(data[0].topic_content))
      setTopics(data)
    }
  }, [data]);


  useEffect(() => {
    let topicsCopy = JSON.parse(JSON.stringify(topics))
    if (topicsCopy.length > 0) {
      topicsCopy[0].topic_content = JSON.stringify(topicContent)
      setTopics(topicsCopy)
    }
  }, [topicContent]);

  function getSubTopicItem(item, name, topic_id) {

    let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
    if (Object.keys(topicContentCopy).includes(`${topic_id}`)) {
      let topicContentList = JSON.parse(topicContentCopy[topic_id])
      if (name === 'sub_topic_name') {
        return item.sub_topic_name
      }
      else {
        let index = name.substring(name.length - 1, name.length)
        if (name.includes("text_only")) {
          return topicContentList[index].detail[0].content
        }
        else if (name.includes("image_and_text")) {
          return topicContentList[index].detail[1].content
        }
        else if (name.includes("text_and_image")) {
          return topicContentList[index].detail[0].content
        }
        else if (name.includes("image_only")) {
          // topicContentList.push(image_only)
        }
        else if (name.includes("code_only")) {
          return topicContentList[index].detail[0].content
        }
        else if (name.includes("text_and_code")) {
          return topicContentList[index].detail[0].content
        }
        else if (name.includes("code_and_text")) {
          return topicContentList[index].detail[1].content
        }
      }
    }

  }

  function renderTopicContentItem(itemDetail, contentType, topic_id, indexContent, indexContentSub, indexMain) {

    let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
    // if (Object.keys(topicContentCopy).includes(`${topic_id}`)) {
    let topicContentList = itemDetail
    if (contentType !== 'image') {
      return (<Box sx={{ display: "flex", flexDirection: "column", m: 0.3, border: '1px solid #CACACA', width: '100%' }}>
        <TextareaAutosize fullWidth minRows={4} name={`${contentType}_{index}`} onChange={(e) => handleTopicTextChange(e, indexContent, indexContentSub, indexMain)} id={topic_id}
          className="text-xs bg-orange-100 w-full"
          value={itemDetail[indexContentSub].content}
        // inputProps={{ 'data-state': isNew }} 
        />
        <Box sx={{ display: "flex", flexDirection: "row", border: '0px solid red', justifyContent: 'space-around' }}>
          <input placeholder="height" name="height" className="text-xs border" onChange={(e) => handleImageProps(e, topic_id, indexContent, indexContentSub, indexMain)} />
          <input placeholder="Width" name="width" className="text-xs border" onChange={(e) => handleImageProps(e, topic_id, indexContent, indexContentSub, indexMain)} />
          {contentType === 'code' && <input className="text-xs" placeholder="language" name="language" size="small" onChange={(e) => handleImageProps(e, topic_id, indexContent, indexContentSub, indexMain)} />}
          {contentType === 'code' && <input className="text-xs" placeholder="showHeader" name="showHeader" size="small" onChange={(e) => handleImageProps(e, topic_id, indexContent, indexContentSub, indexMain)} />}

          <IconButton onClick={(e) => deleteSubTopicSubContent(e, topic_id, indexContent, indexContentSub, indexMain)}><DeleteIcon /></IconButton>


        </Box>
      </Box>)
    }
    else {
      return getImageComponent(itemDetail, topic_id, contentType, indexContent, indexContentSub, indexMain)
    }
    // }

  }



  const handleUpload = (event, indexContent, indexContentSub, indexMain) => {
    let fileName = `/images/${event.target.files[0].name}`

    // let name = event.target.name
    let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
    // let topicContentList = JSON.parse(topicContentCopy[event.target.id])
    // topicContentList[indexContent].detail[indexContentSub].content = fileName
    topicContentCopy[indexMain].content[indexContent].detail[indexContentSub].content = fileName
    // let topicContentList = JSON.parse(topicContentCopy[event.target.id])
    // topicContentList[indexContent].detail[indexContentSub].content = event.target.value
    // topicContentCopy[event.target.id] = JSON.stringify(topicContentList)
    setTopicContent(topicContentCopy)

    // topicContentCopy[event.target.id] = JSON.stringify(topicContentList)
    // setTopicContent(topicContentCopy)

  };


  const handleToolBarOption = (e, index, topic_id, action, indexMain) => {
    index = parseInt(index)
    let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
    if (action === "delete") {
      topicContentCopy[indexMain].content.splice(index, 1)
      setTopicContent(topicContentCopy)
      setUpdated(!updated)
      setMsg("Content Type Deleted")
    }
    else if (action === 'add') {
      let objDict = {}
      objDict.format = "text"
      objDict.detail = ([{ type: 'text', content: 'new Text' }])
      topicContentCopy[indexMain].content.splice(index + 1, 0, objDict)
      setTopicContent(topicContentCopy)
      setUpdated(!updated)
      setMsg("Content Type Added")


    }

  }

  function getImageComponent(itemDetail, topic_id, contentType, indexContent, indexContentSub, indexMain) {
    return (<Box sx={{ display: "flex", flexDirection: "column", border: '1px solid #CACACA', m: 1, justifyContent: 'center' }}>
      <img src={itemDetail[indexContentSub].content} id={topic_id} height={itemDetail[indexContentSub].height} width={itemDetail[indexContentSub].width} sx={{ marginLeft: 'auto', marginRight: 'auto' }} />



      <div className="text-xs flex flex-row">
        <input type="file" onChange={(e) => handleUpload(e, indexContent, indexContentSub, indexMain)} id={topic_id} name={contentType} />

        <TextField placeholder="height" name="height" size="small" value={itemDetail[indexContentSub].height} onChange={(e) => handleImageProps(e, topic_id, indexContent, indexContentSub, indexMain)} />
        <TextField placeholder="Width" name="width" size="small" value={itemDetail[indexContentSub].width} onChange={(e) => handleImageProps(e, topic_id, indexContent, indexContentSub, indexMain)} />
        <IconButton onClick={(e) => deleteSubTopicSubContent(e, topic_id, indexContent, indexContentSub, indexMain)}><DeleteIcon /></IconButton>

      </div>
    </Box>)
  }





  function parseText(text, topic_id, indexMain) {
    try {
      if (text === undefined) {
        return
      }
      let content1 = (text)
      let textContainer = []
      content1.forEach((item, indexContent) => {
        let isNew = item.format.includes("0")
        let arrContentKeys = item.format.split("_")
        textContainer.push(
          <Box sx={{ border: '`px solid red', width: '100%', display: "flex", flexDirection: "row", alignItems: 'center' }}>
            {getToolBarOptions(indexContent, item.format, topic_id, indexMain)}
            <Box name={`text_only${indexContent}`} className="flex flex-row w-full border">
              {arrContentKeys.map((contentType, indexContentSub) =>
                renderTopicContentItem(item.detail, contentType, topic_id, indexContent, indexContentSub, indexMain)
              )}

              <IconButton id={topic_id} onClick={(e) => handleMenuClick(e, indexContent, indexMain)}><AddIcon id={topic_id} /></IconButton>
            </Box>

          </Box>
        )

      })
      // textContainer.push(getAddNewContentControl(topic_id, index))
      return [<Box id={topic_id} sx={{ border: '0px solid red', display: "flex", flexDirection: "column", border: "0px solid red", width: "90%", padding: "1%" }}>{textContainer}</Box>]
    }
    catch (err) {
      console.log(err.message)
      // return < Box sx={{ width: "100%", textAlign: "center" }} > {text} {getAddNewContentControl(topic_id)}</Box >;
    }
  }

  return (
    <Box class="edit-area-main-container">
      <ContentMenu anchorEl={anchorEl} handleMenuClick={handleMenuClick} handleMenuClose={addTopicContent} />
      <Notification open={updated} msg={msg} />
      <div className="text-xl border-2 bg-slate-50">
        {/* <Typography variant="h4" sx={{ display: 'flex', flexGrow: 0.7 }}>{props.topicName}</Typography> */}
        <input type='text' value={topics && (topics.length > 0 && topics[0].blog_title)} defaultValue={topics && (topics.length > 0 && topics[0].blog_title)}
          onChange={handleBlogTitleChange}
          className="w-[100%] text-xl border bg-slate-200"
        />
        <textarea type='text' value={topics && (topics.length > 0 && topics[0].blog_summary)} defaultValue={topics && (topics.length > 0 && topics[0].blog_summary)}
          onChange={handleBlogSummaryChange}
          className="w-[60%] text-xl border bg-slate-200"
        />
        <Button size="small" className="button" onClick={(e) => handleSave(e)}>Save</Button>
      </div>
      <Box class="edit-area-sub-container">

        {(!loading && data) && (topicContent).map((item, indexMain) =>
          <Box class="edit-area-sub-topic-container">

            <Box sx={{ display: "flex", flexDirection: "row", border: "0px solid yellow", width: "100%" }}>
              <Box sx={{ width: '50%', color: 'black' }}>
                <input name="sub_topic_name" id={indexMain} value={item.sub_topic_name} onChange={handleSubTopicChange} className="border w-full" />
              </Box>
              <Box sx={{ width: '50%', display: "flex", alignItems: 'center', justifyContent: 'space-around', flexDirection: "row" }}>
                <Button size="small" onClick={(e) => addSubTopic(e, indexMain)}>Add Sub Topic</Button>
                <Button size="small" onClick={(e) => deleteSubTopic(e, indexMain, item.topic_id)}>Delete Sub Topic</Button>

              </Box>
            </Box>
            <span className="topic-content">{item.content === "" ? "No Content Found" : parseText(item.content, item.blog_id, indexMain)}</span >

          </Box>

        )}

      </Box>

    </Box>
  );
}