import React, { useState, useEffect } from 'react';

function Timer(props) {
  const initialTime = 1200; // 10 minutes in seconds
  const [isRunning, setIsRunning] = useState(true);
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    let interval;

    if (isRunning && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isRunning, time]);


  useEffect(() => {
    if (time === 0) {
        props.setOpen(true)
    }
}, [time])

  const handleStartStop = () => {
    setIsRunning((prevIsRunning) => !prevIsRunning);
  };

  const handleReset = () => {
    setIsRunning(false);
    setTime(initialTime);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

//   if (time===0){
//       alert("Time is up !!")
//   }
//   console.log(time)
  return (
    <div className="text-white rounded-full border-2 pl-2 pr-2">
      {formatTime(time)}
    </div>
  );
};

export default Timer;
