import { Box, getFormLabelUtilityClasses } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from '../../../App';
import CourseDetails from '../../common/courses/CourseDetailsEdit';
import CourseLhn from '../../common/courses/CourseLhnEdit';
import { fetchData, getData } from '../../reusables/ApiCalls';
import { decodeValue } from '../../reusables/Common';
import useApiCall from '../../reusables/useApiCall';
import CodeEditor from '../../common/codeEditor/codeEditor';
import ContentMenu from '../../../Reusables/ContentMenu'
import Notification from '../../../Reusables/Notification';
import './CourseEdit.css';
import { MdSave, MdDelete, MdAdd } from "react-icons/md";
import { TbHandClick } from "react-icons/tb";
import ParseString from '../../common/courses/ParseString';
// import TextContentFormatter from '../../common/textFormatter/TextContentFormatter'
import TextContentFormatter from '../../common/textFormatter/TextContentFormatter';



const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export default function Course(props) {
    const [topicContent, setTopicContent] = React.useState([])
    const [courseTopics, setCourseTopics] = React.useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [indexSub, setIndexSub] = React.useState(0);
    const [courseTopicId, setCourseTopicId] = React.useState(0);
    const [indexContent, setIndexContent] = React.useState(0);
    const [msg, setMsg] = useState("");
    const [updated, setUpdated] = useState(true);

    const myContext = useContext(Context)
    const params = useParams()
    let course_id = decodeValue(params.course_id)
    const { data, loading, error, setRequest } = useApiCall({ method: 'get', payload: [], url: 'http://localhost:3223/course/content?course_id=' + course_id });
    const { data: dataPut, loading: loadingPut, error: errorPut, setRequest: saveTopics } = useApiCall({ method: '', payload: [], url: '' });
    const { data: dataDelete, loading: loadingDelete, error: errorDelete, setRequest: deleteCourseTreeItem } = useApiCall({ method: '', payload: [], url: '' });

    const isEdit = true

    const subTopic = {
        "sub_topic_name": "Sub Topic",
        "content": [
            {
                "format": "text",
                "detail": [
                    {
                        "type": "text",
                        "content": "Sample Text"
                    }
                ]
            }
        ]
    }
    const handleClick = (item) => {
        setTopicContent(parseJSON(item.topic_content))
        setCourseTopicId(item.course_topic_id)
    }

    const handleMenuClick = (event, indexSub, indexContent) => {
        // setTopicId(event.target.id)
        setIndexSub(indexSub)
        setIndexContent(indexContent)
        setAnchorEl(event.currentTarget);

        // setContentIndex(indexContent)
        // setSubIndex(indexMain)
    };

    const handleMenuClose = (event) => {
        // setTopicId(event.target.id)
        let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
        let index_topic = courseTopicsCopy.findIndex(topic => topic.course_topic_id === courseTopicId)
        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
        let topicContentCopy = parseJSON(courseTopicsCopy[index_topic].topic_content)

        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
        let iLen = topicContentCopy[indexSub].content[indexContent].detail.length
        setAnchorEl(null);
        if (event.target.innerText.toLowerCase().includes("text")) {
            topicContentCopy[indexSub].content[indexContent].detail.splice(iLen, 0, { type: 'text', content: 'new Text', width: '100%', height: '100%' })
            topicContentCopy[indexSub].content[indexContent].format = topicContentCopy[indexSub].content[indexContent].format + "_text"
            // topicContentList[contentIndex].format = topicContentList[contentIndex].format + "_text"
            // topicContentList[contentIndex].detail.push({ type: 'text', content: 'new Text', width: '100%', height: '100%' })
        }
        else if (event.target.innerText.toLowerCase().includes("image")) {
            topicContentCopy[indexSub].content[indexContent].detail.splice(iLen, 0, { type: 'image', content: '/images/Advertisement.png', width: '100px', height: '100px' })
            topicContentCopy[indexSub].content[indexContent].format = topicContentCopy[indexSub].content[indexContent].format + "_image"
        }
        else if (event.target.innerText.toLowerCase().includes("code")) {
            topicContentCopy[indexSub].content[indexContent].detail.splice(iLen, 0, { type: 'code', content: 'new Code', width: '100%', height: '100%', language: "java" })
            topicContentCopy[indexSub].content[indexContent].format = topicContentCopy[indexSub].content[indexContent].format + "_code"
        }
        else if (event.target.innerText.toLowerCase().includes("quote")) {
            topicContentCopy[indexSub].content[indexContent].detail.splice(iLen, 0, { type: 'quote', content: 'new Quote', width: '100%', height: '100%' })
            topicContentCopy[indexSub].content[indexContent].format = topicContentCopy[indexSub].content[indexContent].format + "_quote"
        }
        else {
            // setUpdated(!updated)
            // setMsg("Please selected Content Type")
        }
        courseTopicsCopy[index_topic].topic_content = topicContentCopy
        setCourseTopics(courseTopicsCopy)
        console.log(topicContentCopy)
        // setTopicContent(topicContentCopy)
        // setContentIndex(indexContent)
        // setSubIndex(indexMain)
        // courseTopicsCopy[0].topicConten
    };

    const handleAddSubTopic = (index_sub) => {
        let newElement = {
            "sub_topic_name": "Sub Topic Name" + index_sub,
            "content": [
                {
                    "format": "text_code",
                    "detail": [
                        {
                            "type": "text",
                            "content": "<li>Lists are mutable, meaning their elements can be changed after the list is created.</li>\n<li>Tuples are immutable, meaning once they are created, their elements cannot be changed.</li>"
                        },
                        {
                            "type": "code",
                            "content": "\nmy_list = [1, 2, 3]\nmy_tuple = (1, 2, 3)\n\nmy_list[0] = 4  # Valid for lists\n# my_tuple[0] = 4  # Invalid for tuples, will result in an error\n",
                            "width": "100%",
                            "height": "100%",
                            "language": "java"
                        }
                    ]
                }
            ]
        }
        let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
        let index_topic = courseTopicsCopy.findIndex(topic => topic.course_topic_id === courseTopicId)
        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
        let topicContentCopy = parseJSON(courseTopicsCopy[index_topic].topic_content)
        topicContentCopy.splice(index_sub + 1, 0, newElement);
        courseTopicsCopy[index_topic].topic_content = topicContentCopy
        // setTopicContent(topicContentCopy)
        setCourseTopics(courseTopicsCopy)


        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
  
        // topicContentCopy.splice(index_sub + 1, 0, newElement);
        // setTopicContent(topicContentCopy)

    }

    const handleDeleteSubTopic = (index_sub) => {
        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))

        // topicContentCopy.splice(index_sub, 1);
        // setTopicContent(topicContentCopy)
        let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
        let index_topic = courseTopicsCopy.findIndex(topic => topic.course_topic_id === courseTopicId)
        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
        let topicContentCopy = parseJSON(courseTopicsCopy[index_topic].topic_content)
        topicContentCopy.splice(index_sub, 1);
        courseTopicsCopy[index_topic].topic_content = topicContentCopy
        // setTopicContent(topicContentCopy)
        setCourseTopics(courseTopicsCopy)
    }

    const addContentRow = (index_sub, index_content) => {
        let newElement = {
            "format": "text",
            "detail": [
                {
                    "type": "text",
                    "content": "new Text",
                    "width": "100%",
                    "height": "100%"
                }
            ]
        }
        let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
        let index_topic = courseTopicsCopy.findIndex(topic => topic.course_topic_id === courseTopicId)
        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
        let topicContentCopy = parseJSON(courseTopicsCopy[index_topic].topic_content)
        topicContentCopy[index_sub].content.splice(index_content + 1, 0, newElement)
        courseTopicsCopy[index_topic].topic_content = topicContentCopy
        // setTopicContent(topicContentCopy)
        setCourseTopics(courseTopicsCopy)


        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
     
        // topicContentCopy[index_sub].content[index_content].detail[index_format].content
       
        // topicContentCopy[index_sub].content[index_content].detail.splice(index_format + 1, 0, newElement);
        // setTopicContent(topicContentCopy)

    }

    const deleteContentRow = (index_sub, index_content) => {
      
        let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
        let index_topic = courseTopicsCopy.findIndex(topic => topic.course_topic_id === courseTopicId)
        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
        let topicContentCopy = parseJSON(courseTopicsCopy[index_topic].topic_content)
        topicContentCopy[index_sub].content.splice(index_content , 1)
        courseTopicsCopy[index_topic].topic_content = topicContentCopy
        // setTopicContent(topicContentCopy)
        setCourseTopics(courseTopicsCopy)


        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
     
        // topicContentCopy[index_sub].content[index_content].detail[index_format].content
       
        // topicContentCopy[index_sub].content[index_content].detail.splice(index_format + 1, 0, newElement);
        // setTopicContent(topicContentCopy)

    }

    const addContentColumn = (index_format, index_sub, index_content) => {
        let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
        let newElement = {
            "type": "text",
            "content": "New Text Content"
        }
        topicContentCopy[index_sub].content[index_content].format = topicContentCopy[index_sub].content[index_content].format === "" ? "text" : topicContentCopy[index_sub].content[index_content].format + "_text"

        topicContentCopy[index_sub].content[index_content].detail.splice(index_format + 1, 0, newElement);
        setTopicContent(topicContentCopy)

    }


    // const handleSave = (item, index) => {
    //     // let payload = [item]
    //     let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
    //     let copyTopicsParsed=[]
    //     courseTopicsCopy.forEach((item_topic, index_topic) => {
    //         item_topic.topic_order_id = index_topic + 1
    //         item_topic.topic_content = parseJSON(item_topic.topic_content)
    //         copyTopicsParsed.push(item_topic)
    //     }
    //     )
    //     saveTopics({ method: 'put', payload: copyTopicsParsed, url: 'http://localhost:3223/course/content' })
    //     setUpdated(!updated)
    //     setMsg("Saved !!")
    // }

    // const handleSave = (item, index, course_topic_id) => {
    //     // let payload = [item]
    //     let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
    //     let payload = [{ course_topic_id: course_topic_id, topic_content: topicContent, topic_name: item.topic_name, course_id:course_id }]
    //     saveTopics({ method: 'put', payload: payload, url: 'http://localhost:3223/course/content' })
    //     setUpdated(!updated)
    //     setMsg("Saved !!")
    // }

    const handleSave = (item, index, course_topic_id) => {
        // let payload = [item]
        // let courseTopicsParsed=courseTopics.map((item)=>parseJSON(item.topic_content))
        let courseTopicsParsed = courseTopics.map(topic => {
            topic.topic_content = parseJSON(topic.topic_content);
            return topic;
        });
        saveTopics({ method: 'put', payload: courseTopicsParsed, url: 'http://localhost:3223/course/content' })
        setUpdated(!updated)
        setMsg("Saved !!")
    }

    const handleSubTopicChange = (e, item, index) => {
        let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
        courseTopicsCopy[index].topic_name = e.target.value
        setCourseTopics(courseTopicsCopy)
    }


    const handleAdd = (item, index) => {
        let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
        delete item['course_topic_id']
        item.topic_order_id = index + 2
        item.topic_content = parseJSON(item.topic_content)
        courseTopicsCopy.splice(index + 1, 0, item)
        courseTopicsCopy.forEach((item_topic, index_topic) => {
            item_topic.topic_order_id = index_topic + 1
            item_topic.topic_content = parseJSON(item_topic.topic_content)
        }
        )
        setCourseTopics(courseTopicsCopy)
        setUpdated(!updated)
        setMsg("Added !!")
    }

    function parseJSON(value) {
        try {
            value = JSON.parse(value)
            return parseJSON(value)
        }
        catch (err) {
            return value
        }
    }

    const handleDelete = (item, index) => {
        let deleleReq = { method: 'delete', payload: [], url: 'http://localhost:3223/course/content?course_topic_id=' + item.course_topic_id }
        deleteCourseTreeItem(deleleReq)
        let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
        courseTopicsCopy.splice(index, 1)
        setCourseTopics(courseTopicsCopy)
    }


    const handleChange = (e, item, index_format, index_sub, index_content) => {
        let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
        let index_topic = courseTopicsCopy.findIndex(topic => topic.course_topic_id === courseTopicId)
        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
        let topicContentCopy = parseJSON(courseTopicsCopy[index_topic].topic_content)
        topicContentCopy[index_sub].content[index_content].detail[index_format].content = e.target.value
        courseTopicsCopy[index_topic].topic_content = topicContentCopy
        // setTopicContent(topicContentCopy)
        setCourseTopics(courseTopicsCopy)

        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
        // topicContentCopy[index_sub].content[index_content].detail[index_format].content = e.target.value
        // setTopicContent(topicContentCopy)
    }


    const handleContentChange = (newContent, item, index_format, index_sub, index_content) => {
        console.log("in content change...")
        console.log(newContent)
        let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
        let index_topic = courseTopicsCopy.findIndex(topic => topic.course_topic_id === courseTopicId)
        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
        let topicContentCopy = parseJSON(courseTopicsCopy[index_topic].topic_content)
        
        topicContentCopy[index_sub].content[index_content].detail[index_format].content = newContent
        courseTopicsCopy[index_topic].topic_content = topicContentCopy
        // setTopicContent(topicContentCopy)
        setCourseTopics(courseTopicsCopy)

      };


    const handeSubTopicChange = (e, index_sub, item) => {
        let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
        let index_topic = courseTopicsCopy.findIndex(topic => topic.course_topic_id === courseTopicId)
        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
        let topicContentCopy = parseJSON(courseTopicsCopy[index_topic].topic_content)
        topicContentCopy[index_sub].sub_topic_name = e.target.value
        courseTopicsCopy[index_topic].topic_content = topicContentCopy
        // setTopicContent(topicContentCopy)
        setCourseTopics(courseTopicsCopy)

    }

    function getContentFormat(arr_format) {
        let format = ""
        arr_format.forEach(
            (item_f, index_f) => {
                if (index_f === 0) {
                    format = item_f
                }
                else {
                    format = format + "_" + item_f
                }
            }
        )
        return format
    }

    const deleteContentItem = (e, item, index_format, index_sub, index_content) => {
        let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
        let index_topic = courseTopicsCopy.findIndex(topic => topic.course_topic_id === courseTopicId)
        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
        let topicContentCopy = parseJSON(courseTopicsCopy[index_topic].topic_content)
        // let topicContentCopy = JSON.parse(JSON.stringify(topicContent))
        let arr_format = topicContentCopy[index_sub].content[index_content].format.split("_")
        arr_format.splice(index_format, 1)
        topicContentCopy[index_sub].content[index_content].format = getContentFormat(arr_format)
        topicContentCopy[index_sub].content[index_content].detail.splice(index_format, 1);
        courseTopicsCopy[index_topic].topic_content = topicContentCopy
        setCourseTopics(courseTopicsCopy)
        // setTopicContent(topicContentCopy)

    }

    function parseContentItem(item, format, index_format, index_sub, index_content) {
        let courseTopicsCopy = JSON.parse(JSON.stringify(courseTopics))
        let index_topic = courseTopicsCopy.findIndex(topic => topic.course_topic_id === courseTopicId)

        let contentText = item.detail[index_format].content
        let formatDOM = ""
        if (format === "image") {
            formatDOM = <div className="flex flex-col  text-xs "><img src={contentText} height={item.detail[index_format].height} width={item.detail[index_format].width} />
                <input type="file" className="text-xs" /><button onClick={(e) => deleteContentItem(e, item, index_format, index_sub, index_content)} ><MdDelete /></button></div>
        }
        else if (format === "code") {
            formatDOM = isEdit ? <div className="h-full w-full">
                <textarea value={contentText} onChange={(e) => handleChange(e, item, index_format, index_sub, index_content)} className="w-full h-full" />
                <button onClick={(e) => deleteContentItem(e, item, index_format, index_sub, index_content)}><MdDelete /></button></div> : <div className="w-[100%] min-w-[100%]">
                <CodeEditor code={contentText} language={"java"} showHeader={false} /></div>
        }
        else {
            formatDOM = isEdit ? <div className="h-full w-full">
                {/* <textarea value={contentText} onChange={(e) => handleChange(e, item, index_format, index_sub, index_content)} className="w-full h-full" /> */}
                <TextContentFormatter handleContentChange={handleContentChange}
                handleChange={handleChange} 
                itemDetails={[item, index_format, index_sub, index_content]}
                content={courseTopics!==undefined?parseJSON(courseTopics[index_topic].topic_content)[index_sub].content[index_content].detail[index_format].content:""}
                />
                
                <button onClick={(e) => deleteContentItem(e, item, index_format, index_sub, index_content)}><MdDelete /></button>
            </div> : <div><ParseString htmlString={contentText} /></div>
        }

        return (<div className="flex flex-row  h-auto items-center space-x-1">
            {/* <button onClick={(e) => addContentRow(index_format, index_sub, index_content)}>add content row</button> */}
            {formatDOM}
            {/* <button onClick={(e) => addContentColumn(index_format, index_sub, index_content)}>add content col</button> */}
        </div>)

    }


    function contentDom(content, index_sub) {
        return content.map((item, index_content) =>
            <div className="flex flex-row w-full border-solid border-green-500 border-2">
                <div className="flex flex-col items-center h-full  my-auto">
                    <button onClick={(e) => addContentRow(index_sub, index_content)}><MdAdd /></button>
                    <button onClick={(e) => deleteContentRow(index_sub, index_content)}><MdDelete /></button>
                </div>
                <div className="flex flex-row m-1 border w-full">{item.format === "" ? "" : item.format.split("_").map((format_item, index_format) => <span className='w-full'>{parseContentItem(item, format_item, index_format, index_sub, index_content)}</span>)}</div>
                {/* <button onClick={(e) => addContentColumn(item.format.split("_").length - 1, index_sub, index_content)}><MdAdd /></button> */}
                <button onClick={(e) => handleMenuClick(e, index_sub, index_content)}><MdAdd /></button>

            </div>)
    }
    function getTopicContentDom() {
       
        if (courseTopics) {
            let courseTopicRecord=courseTopics.filter(item=>item.course_topic_id===courseTopicId)
            if (courseTopicRecord.length>0) {
                let topicContentObject=parseJSON(courseTopicRecord[0].topic_content)
                return topicContentObject.map((item, index_sub) =>
                    <div className="border-solid border-black border-2">
                        <div className="flex border m-2">
                            {isEdit ? <div className="flex flex-row w-full"><input value={item.sub_topic_name} onChange={(e) => handeSubTopicChange(e, index_sub, item)} className="w-[60%]" />
                                <button onClick={(e) => handleAddSubTopic(index_sub)}><MdAdd /></button>
                                <button onClick={(e) => handleDeleteSubTopic(index_sub)}><MdDelete /></button>
                            </div> : item.sub_topic_name}</div>
                        <div className="flex flex-col  w-full m-1">{contentDom(item.content, index_sub)}
                        </div>
                    </div>

                )
            }
        }
        else {
            return ""
        }
    }
    // const handleClick = async (itemTopic) => {
    //     // const handleClick = (itemTopic) => {
    //     setTopics(itemTopic.topics)
    //     setTopicName(itemTopic.topic_name)
    //     let promises = []

    //     itemTopic.topics.forEach((item, index) => {
    //         promises.push(fetchData(`${REACT_APP_API_URL}/course/topic/lookup?topic_id= ${item.topic_id}`))
    //     })

    //     const data = await Promise.allSettled(promises)
    //     let objDict = {}
    //     data.forEach((item) => {
    //         if (item.value.length > 0) {
    //             objDict[item.value[0].topic_id] = (item.value[0].topic_content)
    //         }
    //     })

    //     setTopicContent(objDict)


    // };
    useEffect(() => {
        setCourseTopics(data)

    }, [data, loading])

    useEffect(() => {
        setUpdated(!updated)
        setMsg("Deleted !!")

    }, [dataDelete, loadingDelete])


    useEffect(() => {
        getTopicContentDom()

    }, [courseTopics, courseTopicId])

    console.log(courseTopics)
    console.log(courseTopicId)
    return (
        <div className="w-full bg-gray-200 flex flex-col">
            <ContentMenu anchorEl={anchorEl} handleMenuClick={handleMenuClick} handleMenuClose={handleMenuClose} handleClose={handleMenuClose} />
            <Notification open={updated} msg={msg} />
            <div className="flex flex-row">
                <div className="flex flex-col w-[30%]  p-1 max-h-[90vh] bg-slate-100 overflow-y-auto">
                    {/* <CourseLhn courseTopics={courseTopics} courseId={course_id} /> */}

                    {courseTopics && courseTopics.map((item, index) =>
                    <div className={`flex flex-row w-full border-b-2 border-slate-400 p-1 ${item.course_topic_id===courseTopicId && "bg-green-200"}`} >
                        {/* <div className="flex flex-row justify-around bg-lime-50 border-b-2 border-slate-400 p-1 w-full"> */}
                            <input className="border" value={item.topic_name} id={`tree-${index}`} onChange={(e) => handleSubTopicChange(e, item, index)} />
                            <button className="text-lg" onClick={(e) => handleClick(item)}><TbHandClick /></button>
                            <button className="text-sm" onClick={(e) => handleSave(item, index, item.course_topic_id)}><MdSave /> </button>
                            <button className="text-sm" onClick={(e) => handleAdd(item, index)}><MdAdd /> </button>
                            <button className="text-sm" onClick={(e) => handleDelete(item, index)}><MdDelete /> </button>
                        </div>)}

                </div>
                <div class="flex flex-col w-[70%]">
                    {/* <CourseDetails topics={topics} topicContent={topicContent} topicName={topicName} courseId={course_id} /> */}
                    {getTopicContentDom()}
                </div>
            </div>
        </div>
    );
}

