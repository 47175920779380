import React from 'react';
import ReactCodeEditor from './ReactCodeEditor';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import JDoodle from './JDoodle'
import CodeCompiler from './compiler/CodeCompiler'
import FullScreenDialog from './dialogs/FullScreenDialog';


function MyCodeEditor() {
  let params = useParams()
  const [open, setOpen] = React.useState(false);

  const handleFullScreen = () => {
    setOpen(!open)
  }



  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Box sx={{marginTop: '70px' }}>
      <FullScreenDialog open={open} handleClickOpen={handleFullScreen} handleClose={handleClose} component={<CodeCompiler language={params.language.toLowerCase()} handleFullScreen={handleFullScreen}  open={open}/>} />
      <CodeCompiler language={params.language.toLowerCase()} handleFullScreen={handleFullScreen} open={open}/>
    </Box>
  );
}

export default MyCodeEditor;
