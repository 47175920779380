import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import MenuBar from '../common/menu/Menubar';
import Header from '../common/header/Header';
import Box from '@mui/material/Box'
import './HomeImage1.css'



export default function HomeImage1() {
  return (
    <div class="relative text-center text-white">
    <img src="images/homeimage4.png" alt="Snow" className="w-full"/>
    <div class="bottom-left">Contact Us for Detailed Online Training Session.</div>
    {/* <div class="top-left"><Header/></div> */}
    {/* <div class="top-right">Top Right</div> */}
    {/* <div class="bottom-right">Contact Us for Detailed Online Training Session.</div> */}
    {/* <div class="centered">Centered</div> */}
  </div>)
}

