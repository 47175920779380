import * as React from 'react';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './LearningCard.css'
import CodeDisplay from '../../../Reusables/CodeDisplay';
import ReactCodeEditor from '../../../Reusables/ReactCodeEditor';

const javaCode=`public class HelloWorld {
  public static void main(String[] args) {
      System.out.println("Hello, World!");
  }
}`
export default function BlogCard(props) {
  return (
    <Box className="learning-card">
      <Box className="learning-title">
        {/* <img src={props.image} height="100%" width="100%"/> */}
        <Typography variant="h4">{props.title}</Typography>
        </Box>
        
      <Box className="learning-code">
        {/* <ReactCodeEditor code={javaCode} language={props.title}/> */}
        <Box className="learning-top"><Typography className="learning-text">{props.text}</Typography></Box>
        <Box className="learning-footer"><a href={`/interview/${props.title.toLowerCase()}`} target="_blank" className="learning-link">Get Started</a></Box>
      </Box>
    </Box>
  );
}