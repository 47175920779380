import { Box } from '@mui/material'
import { BrowserRouter, Route, Routes, Router, HashRouter } from 'react-router-dom';
import ComingSoon from './pages/home/ComingSoon';
import './App.css';
import Home from './pages/home/Home';
import Course from './pages/courses/Course'
import CourseEdit from './pages/courses/CourseEdit'
import Header from './pages/common/header/Header';
import MyCodeEditor from './Reusables/MyCodeEditor';
import { useState, useEffect } from "react";
import BlogDetails from './pages/content/blogs/BlogDetails';
import BlogDetailsEdit from './pages/content/blogs/BlogDetailsEdit'
import BlogList from './pages/content/blogs/BlogList';
import Quiz from './pages/quiz/Quiz';
import QuizEdit from './pages/quiz/QuizEdit';
import QuizHome from './pages/quiz/QuizHome';
import QuizComplete from './pages/quiz/QuizComplete';
import Login from './pages/login/Login'
import ContactUs from './pages/contactus/ContactUs';
import Signup from './pages/login/Signup';
import Profile from './pages/login/Profile';
import useApiCall from './pages/reusables/useApiCall'
const REACT_APP_API_URL = process.env.REACT_APP_API_URL

function Authorize(props) {
  const { data, loading, error, setRequest } = useApiCall({ method: 'get', payload: [], url: `` });


  useEffect(() => {
    if (localStorage.getItem('token')) {
      setRequest({ url: `${REACT_APP_API_URL}/users/token/verify=${localStorage.getItem('token')}`, method: 'get' })
    }
  }, [])

  useEffect(() => {
    if (!loading && data) {
      if(!data.result){
        window.location.href='/login'
      }
    }
  }, [data])

  return (
    <div>
      {props.component}
    </div>
  );
}

export default Authorize;


