import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function ContentMenu(props) {
    const open = Boolean(props.anchorEl);

    return (
        <div>
            <Menu id="basic-menu"
                anchorEl={props.anchorEl}
                open={open}
                onClose={props.handleClose}
             
            >
                <MenuItem onClick={props.handleMenuClose}>Text</MenuItem>
                <MenuItem onClick={props.handleMenuClose}>Code</MenuItem>
                <MenuItem onClick={props.handleMenuClose}>Image</MenuItem>
                <MenuItem onClick={props.handleMenuClose}>Quote</MenuItem>
            </Menu>
        </div >
    );
}