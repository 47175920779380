import React from "react";

import Editor from "@monaco-editor/react";
import './ReactCodeEditor.css'


function ReactCodeEditor(props) {
    const editorOptions = {
        language: props.language,
        theme: 'vs-light',
        lineNumbers: 'off', // Set lineNumbers to "off" to hide line numbers
        minimap: {
            enabled: false, // Disable the minimap
        },
        suggest: { enabled: true },
        automaticLayout: true,
        wordWrap: 'on',
        suggestOnTriggerCharacters: true, // Enable autosuggestions when typing trigger characters
        suggestOnEnter: true,
        scrollbar: {
            vertical: 'hidden', // Hide the vertical scrollbar
            horizontal: 'hidden', // Hide the horizontal scrollbar
            handleMouseWheel: false
        },
        // readOnly: true,
        textAlign: 'center'


    };


    function getLanguage() {
        if (props.language.toLowerCase() === 'c++') {
            return "cpp"
        }
        else if(props.language.toLowerCase() === 'c#'){
            return 'csharp'
        }
        else if(props.language.toLowerCase() === 'vbn'){
            return 'vb'
        }
        else {
            return props.language
        }
    }
    const handleEditorWillMount = (editor, monaco) => {
        monaco?.languages.typescript.javascriptDefaults.setEagerModelSync(true)

        monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
            noSemanticValidation: true,
            noSyntaxValidation: false,
        })

        monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
            target: monaco.languages.typescript.ScriptTarget.ES2016,
            allowNonTsExtensions: true,
            allowJs: true,
            moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
            module: monaco.languages.typescript.ModuleKind.ESNext
        })

        // THIS IS WHAT GOT AUTO COMPLETION WORKING FOR ME
        // In my actual file, I replace the first argument with the `@types/jest` declaration file
        // monaco.editor.createModel('declare const myname: () => "garrett"', 'typescript', monaco.Uri.parse('./types.d.ts'))
    }

    return (
        <Editor
            height="100%"
            defaultLanguage={getLanguage()}
            defaultValue={props.code}
            options={editorOptions}
        // onMount={handleEditorWillMount}
        />
    );
}

export default ReactCodeEditor;
