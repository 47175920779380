import React, { useEffect, useRef } from 'react';

const DocumentArea = React.forwardRef(({ initialContent, onChange }, ref) => {
  const handleInput = (event) => {
    const newContent = event.target.innerHTML;
    onChange(newContent);
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.innerHTML = initialContent;
    }
  }, [initialContent, ref]);

  return (
    <div
      ref={ref}
      contentEditable
      onInput={handleInput}
      style={{ border: '1px solid #ccc', minHeight: '200px', padding: '10px' }}
    />
  );
});

export default DocumentArea;
