import { Box, Typography, Icon } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon';
import './Content.css'
// import LogoSvg from '../../resources/svg/Color logo with background.svg'
import Java from '../../../resources/images/java-logo.png'
import Python from '../../../resources/images/python-logo.png'
// import CLogo from '../../resources/images/c-logo.png'
// import CPlusPlus from '../../resources/images/c++.png'
// import Selenium from '../../resources/images/selenium-logo.png'
// import WebDevelopment from '../../resources/images/web-development-logo.png'
// import Interview from '../../resources/images/interview.png'
// import CodingProblems from '../../resources/images/coding-problems.png'
// import QaTesting from '../../resources/images/qa-testing.png'
// import PracticeQuestions from '../../resources/images/practice-questions.png'
// import SeleniumExceptions from '../../resources/images/selenium-exceptions.png'
// import Pywinauto from '../../resources/images/pywinauto.png'
// import AutomateWisely from '../../resources/images/automate-wisely.jpg'
// import MenuBar from '../common/menu/Menubar'
// import PersonIcon from '@mui/icons-material/Person';
import CourseCard from '../../common/content/CourseCardPlain';


export default function Content() {
    return (
        <Box class="content-main-container">
            <Box class="content-sub-container">
                <Box class="course-section-title">Courses Available</Box>

                <Box class="content-card-container">
                    <Box class="card-container"><CourseCard title="Java" image={Java} /></Box>
                    <Box class="card-container"><CourseCard title="Python" image={Python} /></Box>
                </Box>
                <Box class="content-card-container">
                    <Box class="card-container"><CourseCard title="Javascript" image={Java} /></Box>
                    <Box class="card-container"><CourseCard title="PHP" image={Python} /></Box>

                </Box>
                <Box class="content-card-container">
                    <Box class="card-container"><CourseCard title="C" image={Java} /></Box>
                    <Box class="card-container"><CourseCard title="C++" image={Python} /></Box>

                </Box>
                <Box class="content-card-container">
                    <Box class="card-container"><CourseCard title="C#" image={Java} /></Box>
                    <Box class="card-container"><CourseCard title="VB" image={Java} /></Box>
                </Box>

            </Box>
        </Box>
    );
}

