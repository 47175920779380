import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function QuizDialog(props) {
    


    return (
        <React.Fragment>
            <Dialog open={props.open} onClose={props.handleClose}>
                <DialogTitle>Select your topic </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className="mt-2 mb-2 text-lg flex flex-col">
                        <select id={`category`} onChange={(e) => props.handleCategoryChange(e)} value={props.category} className="p-2 mb-1 border w-full">
                        <option value={0} id={0}>Select Quiz Category</option>
                            {props.dataCategory.map((item_cat) => <option value={item_cat.category_id} id={item_cat.category_id}>{item_cat.category}</option>)}
                        </select>

                        <select id={`subcategory`} onChange={(e) => props.handleSubCategorySelection(e)} value={props.categoryId} className="p-2 border w-full">
                        <option value={0} id={0}>Select Topic</option>
                            {props.subCategoryOptions.map((item_sub_cat) => <option value={item_sub_cat.category_id} id={item_sub_cat.category_id}>{item_sub_cat.content}</option>)}
                        </select>
                        </div>

                        <div className="mt-3 mb-3">
                            Click <b>Okay</b> to submit the Quiz and View Result.
                        </div>

                        <div className="mt-3 mb-3">
                            Click <b>Cancel</b> to stay on Quiz Page.
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleStart}>Okay</Button>
                    <Button onClick={props.handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
