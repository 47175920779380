// import React from 'react';
// import { Typography } from '@mui/material';

// function Quote(props) {

//   return (
//     <Typography variant="h4">
//         {`"${props.text}"`}

//     </Typography>
//   );
// }

// export default Quote;
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa'


const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function Quote(props) {
  return (
    <Card>
      <CardContent sx={{ overflow: 'auto' }}>
        <FaQuoteLeft />
        <Typography variant="h4" sx={{paddingTop:'1%', fontStyle:'italic'}} >
          {props.text}
        </Typography>
        <FaQuoteRight style={{
          float: 'right'
        }}/>
      </CardContent>

    </Card>
  );
}