import useApiCall from '../reusables/useApiCall';
import { useState, useEffect } from 'react';
import CodeEditor from '../common/codeEditor/codeEditor';
import Timer from '../../Reusables/Timer1';
import QuizCategoryDialog from '../../Reusables/dialogs/QuizCategoryDialog';
import QuizScore from '../../Reusables/dialogs/QuizScore';
import TimeUpDialog from '../../Reusables/dialogs/TimeUpDialog';
import TimeProgress from '../../Reusables/TimeProgress'
import Notification from '../../Reusables/Notification';
import { encodeValue } from '../reusables/Common';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL
export default function QuizHome() {
    const { data, loading, error, setRequest } = useApiCall({ url: '', method: 'get', payload: null })
    const { data: dataPut, loading: loadingPut, error: errorPut, setRequest: setRequestPut } = useApiCall({ url: '', method: 'put', payload: null })
    const { data: dataCategory, loading: loadingCategory, error: errorCategory, setRequest: setRequestCategory } = useApiCall({ url: REACT_APP_API_URL + '/quiz/category', method: 'get', payload: null });
    const { data: dataLevel, loading: loadingLevel, error: errorLevel, setRequest: setRequestLevel } = useApiCall({ url: REACT_APP_API_URL + '/quiz/level', method: 'get', payload: null });
    const [correctAnswers, setCorrectAnswers] = useState({});
    const [quesCount, setQuesCount] = useState(0);
    const [generateQuiz, setGenerateQuiz] = useState(false);
    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState("");
    const [categoryId, setCategoryId] = useState(0);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [subCategory, setSubCategory] = useState("");
    const [updated, setUpdated] = useState(true);
    const [msg, setMsg] = useState("");


    const startQuiz = (data) => {
        const quesIds = data.map(question => question.ques_id);
        let payload = [{
            "quiz_ques_id": data,
            "start_time": null,
            "end_time": null,
            "score": null,
            "user_id": 2,
            "createdAt": null,
            "updatedAt": null,
        }]
        setRequestPut({ url: REACT_APP_API_URL + '/quiz/log', method: 'put', payload: payload })
        setGenerateQuiz(true)
        setOpen(false)
        // window.location.href = `${window.location.origin}/quiz/start`
    }

    const handleCategoryChange = (e,) => {
        let subCategories = dataCategory.filter((item) => item.category === e.target.value)
        setCategory(e.target.value)
        if (subCategories.length > 0) {
            setSubCategoryOptions(subCategories[0].sub_category)
        }

    }

    const handleSubCategorySelection = (e, index) => {
        if (e.target.value !== 0) {
            setCategoryId(parseInt(e.target.value))
            setSubCategory(subCategoryOptions.filter((item) => item.category_id === e.target.value)[0].content)
        }
    }

    const handleShowCategoryDialog = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleOkay = () => {
        if (categoryId === 0) {
            setMsg("Please Select Category and Topic")
            setUpdated(!updated)
            setOpen(true)
            return
        }
        setRequest({ url: `${REACT_APP_API_URL}/quiz/listByCategory?category_id=${categoryId}`, method: 'get' })

    }

    useEffect(() => {
        if (!loading && data) {
            setQuesCount(data.length)
            const answerList = data.map(question => question.answer);
            startQuiz(data)
            setCorrectAnswers(answerList)

        }
    }, [data])


    useEffect(() => {
        if (!loadingPut && dataPut && generateQuiz) {
            window.location.href = `${window.location.origin}/quiz/start1/${encodeValue(dataPut[0][0].quiz_log_id)}/${encodeValue(categoryId)}`

        }
    }, [dataPut])


    return (
        <div className="relative bg-slate-100 w-[1/2] m-0">
            <Notification open={updated} msg={msg} />
            {dataCategory && !loadingCategory &&
                <QuizCategoryDialog open={open} handleStart={handleOkay} handleClose={handleClose} dataCategory={dataCategory} handleCategoryChange={handleCategoryChange}
                    handleSubCategorySelection={handleSubCategorySelection}
                    category={category} subCategoryOptions={subCategoryOptions}
                    subCategory={subCategory}
                    categoryId={categoryId}
                />}
            <div className="mb-10 text-left border w-1/2 mx-auto">

                <div className="w-full text-center text-lg font-bold p-4 text-green-700">Welcome to the Quiz Challenge!</div>

                {loading ? "Loading Quiz" : ""}
                <div><b>1. Get Ready:</b> Strap in for a thrilling quiz experience! Ensure you have a stable internet connection and a quiet space to focus.</div>

                <div><b>2. Time Limit:</b> Each question comes with its own ticking clock. You've got a limited time to select your answer, so stay sharp!</div>

                <div><b>3. Multiple Choice:</b> Questions are multiple-choice. Read each option carefully before selecting your answer.</div>

                <div> <b>4. Scoring:</b> Your score will be tallied based on the number of correct answers. Aim for the high score!</div>

                <div><b>5. No Cheating:</b> Keep it fair! Resist the urge to consult external sources. This is your chance to show off what you know.</div>

                <div><b>6. Have Fun:</b> The primary goal is to enjoy the quiz! Challenge yourself and relish the learning experience.</div>

                <div><b>7. Submit Answers:</b> Once you've answered all the questions, hit the submit button. Your results will be displayed instantly.</div>

                <div className="mt-5 text-center">Ready to kick off the Quiz Challenge? Let the games begin!</div>

            </div>

            <button className="p-3 border bg-green-700 text-white" onClick={handleShowCategoryDialog}>Start the Quiz</button>

        </div>
    );
}




