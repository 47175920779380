import React, { useEffect, useState } from 'react';
import { Button, TextField, Box, Snackbar, Divider } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { BiSolidMessageRoundedDetail } from 'react-icons/bi'
import useApiCall from '../reusables/useApiCall';
import './Login.css'
import LogoSvg from '../../resources/svg/Color logo - no background.svg'
import Notification from '../../Reusables/Notification';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [notification, setNotification] = useState([false, "", "success"]);
    const [updated, setUpdated] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const { data, loading, error, setRequest } = useApiCall({ method: '', payload: [], url: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const passwordRegex = /^(?=.*[A-Za-z0-9])(?=.*[^A-Za-z0-9]).{6,}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            setNotification([!notification[0], "Invalid Email Id", "warning"])
        }

        else if (!passwordRegex.test(formData.password)) {
            setNotification([!notification[0], "Invalid Password", "warning"])
        }

        if (
            formData.email.trim() === '' ||
            formData.password.trim() === '') {
            // Show a notification
            setOpenSnackbar(true);
        }
        else {
            let payload = {
                "email_id": formData.email,
                "password": formData.password
            }
            setRequest({ method: 'post', payload: payload, url: REACT_APP_API_URL + '/users/token/generate' });
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };




    let anchorOrigin = { vertical: 'bottom', horizontal: 'right' }; // Replace with your API endpoint

    const { vertical, horizontal } = anchorOrigin;

    console.log(data, error)
    useEffect(() => {
        if (!loading && data) {
            if (data.result) {
                localStorage.setItem("token", data.token)
                localStorage.setItem("emailId", formData.email)
                window.location.href = "/home"
            }
            else {
                setNotification([!notification[0], "Invalid credentials"])
            }
        }

    }, [data])


    useEffect(() => {
        if (error) {
            setNotification([!notification[0], "Unable to Login. Please check credenntials", "error"])
        }

    }, [error])
    return (

        <div className="flex flex-row border-solid  border-slate-400 w-full mx-auto h-[100vh]">
            <Notification open={notification[0]} msg={notification[1]} type={notification[2]} />
            <div className="flex flex-col  w-full bg-green-50 justify-center mx-auto items-center border-r-4 border-green-900">
                {/* <Box className='contact-icon'>  */}
                <img src={LogoSvg} width='70vh' height='auto' style={{ display: 'block' }} />
                {/* </Box> */}

                <div className="text-lg">
                    Login to get saliant features
                </div>
                {/* <Divider /> */}

            </div>
            <div class="flex flex-col bg-green-100 w-full h-full items-center justify-center">
                <form onSubmit={handleSubmit}>



                    <input
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        className="w-[80%] p-1 border-solid border-2 border-slate-10 rounded-lg hover:border-green-500"
                        onChange={handleChange}
                        required
                    />
                    <input
                        placeholder="Password"
                        name="password"
                        value={formData.password}
                        className="w-[80%] p-1 border-solid border-2 border-slate-10 rounded-lg hover:border-green-500"
                        onChange={handleChange}
                        required
                    />



                    <div>Forgot Password ? Click here to reset </div>
                    <div>Not a Member yet ? Click here to Signup </div>
                    <Button type="submit" variant="contained" className="form-submit" onClick={handleSubmit}>
                        Login
                    </Button>

                    
                </form>
                <Snackbar
                    open={openSnackbar}
                    anchorOrigin={{ vertical, horizontal }}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message="Please fill in all fields"
                />
            </div>




        </div>

    );
};

export default Login;
