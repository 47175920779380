import useApiCall from '../reusables/useApiCall';
import { useState, useEffect } from 'react';
import CodeEditor from '../common/codeEditor/codeEditor';
import Timer from '../../Reusables/Timer1';
import QuizDialog from '../../Reusables/dialogs/QuizDialog';
import QuizScore from '../../Reusables/dialogs/QuizScore';
import TimeUpDialog from '../../Reusables/dialogs/TimeUpDialog';
import TimeProgress from '../../Reusables/TimeProgress'
import { useParams } from 'react-router';
import { FaRegSmile } from "react-icons/fa";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { decodeValue, encodeValue } from '../reusables/Common';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL
export default function QuizComplete() {
    let params = useParams()
    const { data, loading, error, setRequest } = useApiCall({ url: REACT_APP_API_URL + '/quiz/list', method: 'get', payload: null })
    const { data: dataPut, loading: loadingPut, error: errorPut, setRequest: setRequestPut } = useApiCall({ url:  '', method: '', payload: null })
    const { data: dataLog, loading: loadingLog, error: errorLog, setRequest: setRequestLog } = useApiCall({ url: REACT_APP_API_URL + '/quiz/log/record?quiz_log_id=' + decodeValue(params.id), method: 'get', payload: null })
    const [correctAnswers, setCorrectAnswers] = useState({});
    const [quesCount, setQuesCount] = useState(0);
    const [generateQuiz, setGenerateQuiz] = useState(false);

    const startQuiz = () => {
        const quesIds = data.map(question => question.ques_id);
        let payload = [{
            "quiz_ques_id": quesIds,
            "start_time": null,
            "end_time": null,
            "score": null,
            "user_id": 2,
            "createdAt": null,
            "updatedAt": null
        }]
        setRequestPut({ url: REACT_APP_API_URL + '/quiz/log', method: 'put', payload: payload })
        setGenerateQuiz(true)
        // window.location.href = `${window.location.origin}/quiz/start`
    }


    useEffect(() => {
        if (!loading && data) {
            setQuesCount(data.length)
            const answerList = data.map(question => question.answer);
            setCorrectAnswers(answerList)

        }
    }, [data])


    // useEffect(() => {
    //     if (!loadingPut && dataPut && generateQuiz) {
    //         window.location.href = `${window.location.origin}/quiz/start/${dataPut[0][0].quiz_log_id}`

    //     }
    // }, [dataPut])

    useEffect(() => {
        if (!loadingPut && dataPut && generateQuiz) {
            window.location.href = `${window.location.origin}/quiz/start1/${encodeValue(dataPut[0][0].quiz_log_id)}/${encodeValue(dataLog.category_id)}`

        }
    }, [dataPut])

    let msg = ""
    let title = ""
    if (dataLog) {
        if (dataLog.score > 75) {
            title = <div className="flex flex-row space-x-1 w-full text-center justify-center items-center"><FaRegSmile size={40} />
                <span>Congratulations! You aced the quiz – your knowledge shines!</span></div>
            msg = <div className="flex flex-col text-center">
                <span className="mt-2 font-bold">Here is your score : </span>
                <span className="bg-blue-500 w-[50px] rounded-full h-12 items-center flex justify-center mx-auto">{`${dataLog.score}%`}</span>
            </div>
        }
        else {
            title = <div className="flex flex-row space-x-1 w-full text-center justify-center items-center"><HiOutlineEmojiSad size={40} />
            <span>Better luck next time – every setback is a setup for a comeback!</span></div>
        msg = <div className="flex flex-col text-center">
            <span className="mt-2 font-bold">Here is your score : </span>
            <span className="bg-blue-800 text-white font-bold w-[50px] rounded-full h-12 items-center flex justify-center mx-auto">{`${dataLog.score}%`}</span>
        </div>
        }
    }

    return (
        <div className="mt-[70px] relative  w-[1/2] p-5">

            <div className="border w-1/2  bg-slate-100 mt-10 flex flex-col justify-center mx-auto rounded-lg">

                <div className="w-full text-center text-lg font-bold p-4 text-green-700">{title}</div>



                <div className="mt-5 text-center">{msg}</div>
                <button className="p-2 border bg-green-700 text-white mt-10 w-[1/2]" onClick={startQuiz}>Retake</button>

            </div>


        </div>
    );
}




