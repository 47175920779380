import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useEffect, useState } from 'react';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Notification(props) {
    const [open, setOpen] = React.useState(props.open);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        setOpen(true)
      }, [props.open]);
    

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open && props.msg!==""} autoHideDuration={4000} onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}

            >
                <Alert onClose={handleClose} severity={!props.type?"success":props.type} sx={{ width: '100%' }}>
                    {props.msg}
                </Alert>
            </Snackbar>
        </Stack>
    );
}