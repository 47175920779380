import useApiCall from '../reusables/useApiCall';
import { useState, useEffect } from 'react';
import CodeEditor from '../common/codeEditor/codeEditor';
import Timer from '../../Reusables/Timer1';
import QuizDialog from '../../Reusables/dialogs/QuizDialog';
import QuizScore from '../../Reusables/dialogs/QuizScore';
import TimeUpDialog from '../../Reusables/dialogs/TimeUpDialog';
import TimeProgress from '../../Reusables/TimeProgress'
import { useParams } from 'react-router';
import Pagination from '@mui/material/Pagination';
import { decodeValue, encodeValue } from '../reusables/Common';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL
export default function Quiz() {
    let params = useParams()
    const [isEncoded, setIsEncoded] = useState(false);
    const { data, loading, error, setRequest } = useApiCall({ url: `${REACT_APP_API_URL}/quiz/listByCategory?category_id=${decodeValue(params.categoryId)}`, method: 'get', payload: null })
    const { data: dataPut, loading: loadingPut, error: errorPut, setRequest: setRequestPut } = useApiCall({ url: '', method: '', payload: null })
    const { data: dataCategory, loading: loadingCategory, error: errorCategory, setRequest: setRequestCategory } = useApiCall({ url: REACT_APP_API_URL + '/quiz/category/id?category_id=' + decodeValue(params.categoryId), method: 'get', payload: null });

    const [answers, setAnswers] = useState({});
    const [correctAnswers, setCorrectAnswers] = useState({});
    const [quesCount, setQuesCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [score, setScore] = useState(0);
    const [submit, setSubmit] = useState(false);
    const [openTimeUp, setOpenTimeUp] = useState(false);
    const [quesList, setQuesList] = useState([]);
    const [finishQuiz, setFinishQuiz] = useState(false);
    const [ques_index, setQuesIndex] = useState(0);
    const [currentQues, setCurrentQuestion] = useState(null);
    const [loadingAll, setLoadingAll] = useState(true);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function parseJSON(value) {
        try {
            value = JSON.parse(value)
            return parseJSON(value)
        }
        catch (err) {
            return value
        }
    }

    const handleCloseScoreDialog = () => {
        setSubmit(false);
        setOpen(false);
        let payload = [{
            "quiz_log_id": decodeValue(params.id),
            "score": score,
            "correct_answers": correctAnswers,
            "user_id": 2
        }]
        setRequestPut({ url: REACT_APP_API_URL + '/quiz/log', method: 'put', payload: payload })
        setFinishQuiz(true)
    };

    const handleCloseTimeUpDialog = () => {

        // setSubmit(false);
        // setOpen(false);
        // let payload = [{
        //     "quiz_log_id": params.id,
        //     "score": getScore(),
        //     "correct_answers":correctAnswers,
        //     "user_id": 2
        // }]
        // setRequestPut({ url: REACT_APP_API_URL + '/quiz/log', method: 'put', payload: payload })
        // setFinishQuiz(true)
        // setSubmit(true);
        decodeParams()
        let payload = [{
            "quiz_log_id": decodeValue(params.id),
            "score": getScore(),
            "category_id": params.categoryId,
            "user_answers": answers,
            "correct_answers": correctAnswers,
            "user_id": 2
        }]
        setRequestPut({ url: REACT_APP_API_URL + '/quiz/log', method: 'put', payload: payload })
        setFinishQuiz(true)
        setOpen(false);
        setOpenTimeUp(false)
    };

    const handleOpenScore = () => {
        // setSubmit(true);
        setSubmit(false);
        setOpen(false);
        let payload = [{
            "quiz_log_id": decodeValue(params.id),
            "score": getScore(),
            "category_id": params.categoryId,
            "user_answers": answers,
            "correct_answers": correctAnswers,
            "user_id": 2
        }]
        setRequestPut({ url: REACT_APP_API_URL + '/quiz/log', method: 'put', payload: payload })
        setFinishQuiz(true)
        setOpen(false);
    };


    const handleAnswerChange = (questionId, selectedOption) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: selectedOption,
        }));
    };


    function generateRandomNumberList(length, min, max) {
        return Array.from({ length: length }, () => Math.floor(Math.random() * (max - min + 1)) + min);
    }

  

    const handleSubmit = () => {
        // You can handle the submission of answers here
        getScore()
        setOpen(true)
    };

    function getScore() {
        let arr_ques_id = Object.keys(correctAnswers)
        let correctCount = 0
        arr_ques_id.forEach((key) => {
            if (correctAnswers[key] === answers[key]) {
                correctCount = correctCount + 1
            }

        })
        let percent = correctCount * 100 / quesCount
        // alert(`Your Score ${parseInt(percent)}%`);
        setScore(percent)
        return percent
    }

    function getQuizSummary() {
        let arr_ques = Object.keys(answers)
        let completed = arr_ques.length
        let pending = quesCount - completed
        return { completed: completed, pending: pending }
    }

    const handleNext = (e, value) => {
        let questionItem = parseJSON(JSON.stringify(data[value - 1]))
        setCurrentQuestion(questionItem)
        setQuesIndex(value - 1)
    }

    const CodeEditorCopy = ({ ...props }) => {
        return <CodeEditor {...props} />;
    };

    function getQuestionBlock(item) {
        if (!item) {
            return ""
        }
        let question = parseJSON(data[ques_index].question)
        let options = parseJSON(data[ques_index].options)
        let correctAnswer = item.answer
        let classQuestion = "w-3/4  text-center flex flex-col justify-center"
        let codeEditor = [<CodeEditorCopy code={question.code} language={"python"} showHeader={false} theme="dark" />]
        return (
            <div className="m-1 w-full flex flex-row text-center justify-center">

                <div className={classQuestion}>
                    <div className="w-full m-2 font-bold text-left mx-auto">{`${question.text}`}</div>
                    {/* {question.code && <div className="w-full mx-auto"><CodeEditor code={question.code} language={"python"} showHeader={false} theme="dark" /></div>} */}
                    {question.code && <div className="w-full mx-auto">{codeEditor}</div>}
                    <div className="w-full flex flex-col space-y-2 text-left border-solid border-t-2 border-slate-300">{Object.keys(options).map((itemOption) =>
                        <label>
                            <input
                                type="radio"
                                name={`${item.ques_id}-${itemOption}`}
                                value={itemOption}
                                checked={String(answers[item.ques_id]) === String(itemOption)}
                                onChange={() => handleAnswerChange(item.ques_id, itemOption)}
                            />
                            <span className="text-xs"> {options[itemOption]}</span>
                        </label>)

                    }
                    </div>
                </div>
            </div>
        )
    }

    function decodeParams(params) {
        try {
            params.categoryId = decodeValue(params.categoryId)
            params.id = decodeValue(params.id)
            setIsEncoded(true)
            return params
        }
        catch (err) {
            setIsEncoded(true)
            return params
        }
    }

    // useEffect(() => {
    //     decodeParams(params)
    //     if (isEncoded) {
    //         setRequest({ url: `${REACT_APP_API_URL}/quiz/listByCategory?category_id=${params.categoryId}`, method: 'get', payload: null })
    //         setRequestCategory({ url: REACT_APP_API_URL + '/quiz/category/id?category_id=' + params.categoryId, method: 'get', payload: null });
    //     }

    // }, [isEncoded])


    useEffect(() => {
        setLoadingAll(loading && loadingPut && loadingCategory)
    }, [loading, loadingPut, loadingCategory])

    useEffect(() => {
        if (!loading && data) {
            setQuesCount(data.length)
            // setCurrentQuestion(parseJSON(JSON.stringify(data[0])))
            setCurrentQuestion(data[0])
            const quesIds = data.map(question => question.ques_id);
            // const answerList = data.map(question => question.answer);
            data.forEach((question) => {
                setCorrectAnswers((prevAnswers) => ({
                    ...prevAnswers,
                    [question.ques_id]: question.answer,
                }));

            })
        }
    }, [data])


    useEffect(() => {
        if (!loadingPut && dataPut && finishQuiz) {
            window.location.href = `${window.location.origin}/quiz/finish/${encodeValue(dataPut[0][0].quiz_log_id)}`
        }
    }, [dataPut])


    console.log(currentQues)

    let objNoQuestion = <div className="h-[30vh] relative bg-slate-100 flex items-center">No Questions</div>
    let objLoading = <div className="h-[30vh] relative bg-slate-100 flex items-center">Loading...</div>
    let objTimeUp = <div className="h-[30vh] relative bg-slate-100 flex items-center">Time is Up !!</div>

    console.log(isEncoded, loading, loadingPut, loadingCategory, open, openTimeUp, loadingAll, data, currentQues    )

    return (
        <div className="relative">
            <QuizDialog open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} handleSubmit={handleOpenScore} summary={getQuizSummary()} />
            <TimeUpDialog open={openTimeUp} handleClose={handleCloseTimeUpDialog} score={score} />
            {(!loadingAll) ?
                <><div className="flex justify-around bg-green-700 p-2 fixed top-16 left-0 w-full z-50 text-white">
                    <span className="text-lg">{dataCategory && dataCategory.category}</span>
                    <span className="text-lg">{dataCategory && dataCategory.sub_category}</span>
                    <button onClick={handleSubmit} className="bg-slate-100 pl-4 pr-4 rounded-xl font-bold text-black">Submit</button>
                    <div className="right-20"><Timer setOpen={setOpenTimeUp} /></div>
                </div>
                    <div className="flex flex-col w-[50%] border-[1px] border-green-900 rounded-xl  mx-auto mt-28 bg-slate-100">
                        <div className="flex flex-row justify-center w-full  mx-auto border p-2">
                            <Pagination count={10} onChange={handleNext} boundaryCount={10} shape="rounded" variant="outlined" />
                        </div>
                        {openTimeUp ? objTimeUp : loading ? objLoading : (!data || data.length === 0 ? objNoQuestion : getQuestionBlock(currentQues))}

                    </div></> :

                <div className="bg-blue-200 h-44 flex items-center"><a href="/quiz/landing" className="mt-10 p-3 border bg-green-700 text-white">Start A New Quiz</a> </div>
            }

        </div>
    );
}




