import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function TimeUpDialog(props) {


    return (
        <React.Fragment>
            <Dialog open={props.open} onClose={props.handleClose} fullWidth={true} maxWidth="md">
                <DialogTitle>Time is Up</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                       Sorry, time is out !
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Okay</Button>
                    {/* <Button onClick={props.handleClose}>Cancel</Button> */}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
