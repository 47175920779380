import { Box, Typography, Icon } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon';
import './Learnings.css'
// import LogoSvg from '../../resources/svg/Color logo with background.svg'
import Java from '../../../resources/images/java-logo.png'
import Python from '../../../resources/images/python-logo.png'
// import CLogo from '../../resources/images/c-logo.png'
// import CPlusPlus from '../../resources/images/c++.png'
// import Selenium from '../../resources/images/selenium-logo.png'
// import WebDevelopment from '../../resources/images/web-development-logo.png'
import Interview from '../../../resources/images/interview.png'
import CodingProblems from '../../../resources/images/coding-problems.png'
import QaTesting from '../../../resources/images/qa-testing.png'
// import PracticeQuestions from '../../resources/images/practice-questions.png'
// import SeleniumExceptions from '../../resources/images/selenium-exceptions.png'
// import Pywinauto from '../../resources/images/pywinauto.png'
// import AutomateWisely from '../../resources/images/automate-wisely.jpg'
// import MenuBar from '../common/menu/Menubar'
// import PersonIcon from '@mui/icons-material/Person';
import LearningCard from '../../common/content/LearningCard';
import LearningsText from './LearningsText'

const text = `a`
export default function Blogs() {
    return (
        <Box class="learning-main-container">

            <Box class="learning-sub-container">
                <Box class="learning-section-title">Learning Essentionals</Box>

                <Box class="learning-card-container">

                    <Box class="learning-container"><LearningCard title="Interview Questions" image={Interview} text={LearningsText['interview questions']}/></Box>
                    <Box class="learning-container"><LearningCard title="Coding Problems" image={CodingProblems} text={LearningsText['coding problems']}/></Box>
                </Box>
                <Box class="learning-card-container">

                    <Box class="learning-container"><LearningCard title="Quiz" image={Interview} text={LearningsText['quiz']}/></Box>
                    <Box class="learning-container"><LearningCard title="Forum" image={CodingProblems} text={LearningsText['forum/community']}/></Box>
                </Box>

            </Box>
   
        </Box>
    );
}

