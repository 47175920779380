import React from 'react';
// import Select from 'react-select';
import './Navbar.css';
import { Box, IconButton, Button } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { RiFontSize } from 'react-icons/ri'
import { MdLightMode, MdOutlineLightMode } from 'react-icons/md'
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai'
import { BiSolidDockBottom, BiSolidDockRight } from 'react-icons/bi'


const Navbar = (props) => {
	// const [userLang, setUserLang]=React.useState("java")
	// const [userTheme, setUserTheme]=React.useState("light")
	// const [fontSize, setFontSize]=React.useState(12)

	const languages = [
		{ value: "c", label: "C" },
		{ value: "cpp", label: "C++" },
		{ value: "python", label: "Python" },
		{ value: "csharp", label: "C#" },
		{ value: "java", label: "Java" },
		{ value: "php", label: "PHP" },
		{ value: "perl", label: "PERL" },
		{ value: "ruby", label: "RUBY" },
		{ value: "vbn", label: "VB.Net" },
		{ value: "groovy", label: "Groovy" },
		{ value: "nodejs", label: "Javascript" },
	];
	const themes = [
		{ value: "vs-dark", label: "Dark" },
		{ value: "light", label: "Light" },
	]



	return (
		<Box className="navbar">
			{/* <h1> Code Compiler</h1> */}
			<FormControl sx={{ minWidth: 120 }} size="small">
				{/* <InputLabel id="demo-select-small-label">Language</InputLabel> */}
				<Select
					value={props.userLang}
					variant="outlined"
					label="Language"
					sx={{ background: 'white', height: '30px', border:'0px solid #CCC' }}
					onChange={(e) => {
						props.setUserLang(e.target.value);
						window.location.pathname=`/compiler/${e.target.value}`
					}
					}
				>
					{languages.map((item) => <MenuItem className="lang-menu" value={item.value}>{item.label}</MenuItem>)}
				</Select>
			</FormControl>

			<IconButton onClick={props.handleFontSizeLess}><RiFontSize size={15} color="green" /></IconButton>
			<IconButton onClick={props.handleFontSizeMore}><RiFontSize size={20} color="green" /></IconButton>
			<IconButton onClick={props.handleViewMode}>{props.userTheme ? <MdOutlineLightMode size={20} color="green" /> : <MdLightMode size={20} color="green" />}</IconButton>
			{/* {!props.open && <IconButton onClick={props.handleDock}>{props.dock ? <BiSolidDockBottom size={20} color="green" /> : <BiSolidDockRight size={20} color="green" />}</IconButton>} */}
			<IconButton onClick={props.handleFullScreen}>{!props.open ? <AiOutlineFullscreen size={20} color="green" /> : <AiOutlineFullscreenExit size={20} color="green" />}</IconButton>
			<Button variant="outlined" size="small" onClick={() => props.executeCode()}>
				Run
			</Button>
			<Button onClick={() => { props.clearOutput() }}
				variant="outlined" size="small">
				Clear
			</Button>
			{/* <Select className="select-option" options={languages} value={userLang}
				onChange={(e) => setUserLang(e.value)}
				placeholder={userLang}
				size="small" /> */}
			{/* <Select options={themes} value={userTheme}
				onChange={(e) => setUserTheme(e.value)}
				placeholder={userTheme} /> */}
			{/* <label>Font Size</label>
			<input type="range" min="12" max="30"
				value={fontSize} step="2"
				onChange={(e) => { setFontSize(e.target.value) }} /> */}
		</Box>
	)
}

export default Navbar

