import { Box } from '@mui/material'
import { BrowserRouter, Route, Routes, Router, HashRouter } from 'react-router-dom';
import ComingSoon from './pages/home/ComingSoon';
import './App.css';
import Home from './pages/home/Home';
import Course from './pages/courses/Course'
import CourseEdit from './pages/cms/courses/CourseEdit'
import CourseDetail from './pages/app/courses/CourseDetail'
import Header from './pages/common/header/Header';
import MyCodeEditor from './Reusables/MyCodeEditor';
import { useState, createContext } from "react";
import BlogDetails from './pages/content/blogs/BlogDetails';
import BlogDetailsEdit from './pages/content/blogs/BlogDetailsEdit'
import BlogList from './pages/content/blogs/BlogList';
import Quiz from './pages/quiz/Quiz';
import QuizEdit from './pages/quiz/QuizEdit';
import QuizHome from './pages/quiz/QuizHome';
import QuizComplete from './pages/quiz/QuizComplete';
import Login from './pages/login/Login'
import ContactUs from './pages/contactus/ContactUs';
import Signup from './pages/login/Signup';
import Profile from './pages/login/Profile';
import Authorize from './Authorize';

export const Context = createContext()


function App() {
  const [val, setVal] = useState({ isSaved: false });

  function hideHeader() {
   return  window.location.pathname==="/" || window.location.pathname==="/home" || window.location.pathname.toLowerCase().includes("signup") || window.location.pathname.toLowerCase().includes("login")
  }

  return (
    <BrowserRouter>
      <div className="App">
        <Context.Provider value={[val, setVal]}>
          {/* <Header /> */}
          {hideHeader() ? <></> : <Header />}
          {/* {[`${window.location.origin}/login`,`${window.location.origin}/signup`].includes(window.location.pathname.toLowerCase()) ? "": <Header />} */}
          {/* {<Header />} */}
          <Routes>
            <Route path={"/"} element={<ComingSoon />} />
            {/* <Route path={"/home"} element={<Home />} /> */}
            <Route path={"/home"} element={<Authorize component={<ComingSoon />} />} />
            <Route path={"/home1"} element={<Authorize component={<Home />} />} />
            <Route path={"/course/:course_name/:course_id"} element={<CourseDetail />} />
            {/* <Route path={"/course/:course_name/:course_id/:topic_id"} element={<Course />} /> */}
            <Route path={"/course/:course_name/:course_id/edit"} element={<CourseEdit title="Java" />} />
            <Route path={"/compiler/:language"} element={<MyCodeEditor />} />
            <Route path={"/blog/:blog_id"} element={<BlogDetails />} />
            <Route path={"/blog/:blog_id/edit"} element={<BlogDetailsEdit />} />
            <Route path={"/blog/list"} element={<BlogList />} />
            <Route path={"/quiz/landing"} element={<QuizHome />} />
            <Route path={"/quiz/finish"} element={<QuizComplete />} />
            <Route path={"/quiz/finish/:id"} element={<QuizComplete />} />
            <Route path={"/quiz/start"} element={<Quiz />} />
            <Route path={"/quiz/start1/:id/:categoryId"} element={<Quiz />} />
            <Route path={"/quiz/edit"} element={<QuizEdit />} />
            <Route path={"/login"} element={<Login />} />
            <Route path={"/signup"} element={<Signup />} />
            <Route path={"/contactus"} element={<ContactUs />} />
            <Route path={"/profile"} element={<Profile />} />
          </Routes>

        </Context.Provider>
      </div>
    </BrowserRouter>

  );
}

export default App;


