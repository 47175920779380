import * as React from 'react';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './CourseCardPlain.css'
import CodeDisplay from '../../../Reusables/CodeDisplay';
import ReactCodeEditor from '../../../Reusables/ReactCodeEditor';
import HelloWorldText from './HelloWorld';

export default function CourseCardPlain(props) {

	function getLangauage() {
		if (props.title.toLowerCase() === "python") {
			return "python3"
		}
		else if (props.title.toLowerCase() === "c++") {
			return "cpp"
		}
		else if (props.title.toLowerCase() === "c#") {
			return "csharp"
		}
    else if (props.title.toLowerCase() === "c#") {
			return "csharp"
		}
    else if (props.title.toLowerCase() === "vbn") {
			return "vb"
		}
    else {
			return props.title.toLowerCase()
		}
	}

  return (
    <Box className="course-card">
      <Box className="tile-title"><Typography variant="h4" >{props.title}</Typography></Box>
      <Box className="tile-code">
      <Box className="code-content"><ReactCodeEditor code={HelloWorldText[props.title.toLowerCase()]} language={props.title.toLowerCase()}/></Box>
      <Box className="tile-button">  <a href={`/compiler/${getLangauage(props.title.toLowerCase())}`} target="_blank" className="card-button">Give it a try !</a></Box>
      </Box>
    </Box>
  );
}