import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import ParseString from '../courses/ParseString';
import DocumentArea from './DocumentArea';
import parse from 'html-react-parser';
import './formatter.css'
import Editor from "@monaco-editor/react";
import { createRoot } from 'react-dom/client';

const ToolbarButton = ({ icon, onClick }) => {
  return (
    <button onClick={onClick} aria-label={icon}>
      {icon}
    </button>
  );
};

const options = {
  fontSize: '13px',
  minimap: {
    enabled: false, // Disable the minimap
  },
  readOnly: false, // Make the editor readonly'
  scrollBeyondLastLine: false,
  lineNumbers: "off",
  wordWrap: 'on', // 'on' enables word wrap
  wordWrapColumn: 80, // Opt
}

const Toolbar = ({ onBold, onItalic, onList, onOrderedList }) => {
  return (
    <div>
      <ToolbarButton icon="B" onClick={onBold} />
      <ToolbarButton icon="I" onClick={onItalic} />
      <ToolbarButton icon="UL" onClick={onList} />
      <ToolbarButton icon="OL" onClick={onOrderedList} />
    </div>
  );
};

// const DocumentArea = React.forwardRef(({ onChange }, ref) => {
//   const handleInput = (event) => {
//     const newContent = event.target.innerHTML;
//     onChange(newContent);
//   };

//   return (
//     <div
//       ref={ref}
//       contentEditable
//       onInput={handleInput}
//       style={{ border: '1px solid #ccc', minHeight: '200px', padding: '10px' }}
//     />
//   );
// });

export default function TextContentFormatter(props) {
  const [content, setContent] = useState('');
  const docRef = useRef(null);

  const applyFormatting = (command) => {
    if (docRef.current) {
      document.execCommand(command, false, null);
      docRef.current.focus();
    }
  };

  const handleBoldClick = () => {
    applyFormatting('bold');
  };

  const handleItalicClick = () => {
    applyFormatting('italic');
  };

  const handleListClick = () => {
    applyFormatting('insertUnorderedList');
  };

  const handleOrderedListClick = () => {
    applyFormatting('insertOrderedList');
  };

  // const handleContentChange = (newContent) => {
  //   setContent(newContent);
  // };
  let itemDetails = props.itemDetails
  return (
    <div className="w-full">
      <Toolbar
        onBold={handleBoldClick}
        onItalic={handleItalicClick}
        onList={handleListClick}
        onOrderedList={handleOrderedListClick}
      />
      <DocumentArea
        ref={docRef}
        initialContent={props.content}

        onChange={newContent => props.handleContentChange(newContent, itemDetails[0], itemDetails[1], itemDetails[2], itemDetails[3])} />
      <div className="w-full flex flex-row">
        <div className="flex-1 w-full">
          <h3>Content:</h3>
          {/* <textarea 
          value={props.content} 
          onChange={(e) => props.handleChange(e, itemDetails[0], itemDetails[1], itemDetails[2], itemDetails[3])} 
          className="w-full h-64 border rounded p-2" 
        /> */}

          <Editor
            options={options}
            height={"100px"}
            theme={'vs-light'}
            language={"html"}
            defaultValue={props.content}
            value={props.content}
            onChange={(e) => props.handleChange(e, itemDetails[0], itemDetails[1], itemDetails[2], itemDetails[3])} 
          />
        </div>
        {/* <div>
         
          <ParseString htmlString= {props.content} />
        </div> */}
      </div>
    </div>
  );
};

const root = createRoot(document.getElementById("root"));
// ReactDOM.render(<TextContentFormatter/>, document.getElementById('root'));
root.render(<React.StrictMode>
  <TextContentFormatter />
</React.StrictMode>
);