import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LogoSvg from '../../../resources/svg/Color logo - no background.svg'
import SvgPython from '../../../resources/svg/python-logo.svg'
import SvgJava from '../../../resources/svg/java-logo.svg'
// import LogoSvg from 'public/Logo.png'
import './MenuBarConcise.css'
import Link from '@mui/material/Link';
import { FaJava, FaHome, FaUser } from 'react-icons/fa'
import { AiFillHome, AiTwotoneMail, AiFillAmazonSquare } from 'react-icons/ai'
import { BiLogoJava, BiLogoReact, BiLogoPython, BiLogoJavascript, BiLogoAws } from 'react-icons/bi'
import { SiSelenium } from 'react-icons/si'
import { FaUserCircle, FaAws } from 'react-icons/fa'
import { FaXTwitter, FaFacebook, FaSquareYoutube, FaLinkedin } from 'react-icons/fa6'
import { RiMiniProgramFill } from 'react-icons/ri'
import { MdGroup, MdMenuBook, MdQuestionAnswer } from 'react-icons/md'
import { IoIosArrowDropdownCircle } from 'react-icons/io'
import { TbCircleLetterC, TbBrandAws } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom';
import { GiBlackBook } from "react-icons/gi";
import Menu from '@mui/material/Menu';
import SvgIcon from '@mui/material/SvgIcon';
import MenuItem from '@mui/material/MenuItem';
import { CgLogOut } from "react-icons/cg";
import { encodeValue } from '../../reusables/Common';


const drawerWidth = 240;

const courseList = ['java', 'python', 'javascript', 'c', 'web automation', 'aws cloud', 'reactjs']

function MenuAppBar(props) {
  const history = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElCourse, setAnchorElCourse] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const open = Boolean(anchorEl);
  const openCourse = Boolean(anchorElCourse);
  const openUserMenu = Boolean(anchorElUser);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleClickCourse = (event) => {
    setAnchorElCourse(event.currentTarget);
  };

  const handleClose = (e) => {
    if (e.target.innerText.toLowerCase() === "logout") {
      localStorage.clear()
      history('/login');

    }
    else if (e.target.innerText.toLowerCase() === "profile") {
      history('/profile');

    }
    setAnchorEl(null);
    setAnchorElCourse(null);
    setAnchorElUser(null)
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const navItems = props.courses.map((item) => item.course_name)

  let courseMenuCss = "flex flex-row items-center p-2 w-full"

  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Coding Academy India
      </Typography>
      <Divider />
      <List>
        {props.courses.map((item) => (
          // <ListItem key={item.course_name} disablePadding>
          //   <ListItemButton sx={{ textAlign: 'center' }}>
          //     <ListItemText primary={getMenuItem(item.course_name)} />
          //   </ListItemButton>
          // </ListItem>
          <Link href={`/course/${item.course_name}/${encodeValue(item.course_id)}`} key={item.course_name} class='menu-app-list' underline="none" key={item.course_name} id={item.course_id}>
            {getCourseMenuItem(item.course_name)}
          </Link>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  function getCourseID(courses, course_name) {
    let courseRecord = courses.filter(item => item.course_name.toLowerCase() === course_name.toLowerCase())
    if (courseRecord.length > 0) {
      return courseRecord[0].course_id
    }
    else {
      return 0
    }

  }

  function getMenuItem(course_name) {
    let text = ""
    switch (course_name.toLowerCase()) {
      case 'java':
        text = [<><FaJava className="header-icon java" /><b>{course_name}</b></>]
        // text=[<><SvgIcon className="header-icon">{SvgJava}</SvgIcon><b>{course_name}</b></>]
        break;
      case 'home':
        text = [<><AiFillHome className="header-icon home" /><b>{course_name}</b></>]
        break;
      case 'python':
        text = [<><BiLogoPython className="header-icon python" /><b>{course_name}</b></>]
        break;
      case 'javascript':
        text = [<><BiLogoJavascript className="header-icon javascript" /><b>{course_name}</b></>]
        break;
      case 'c':
        text = [<><TbCircleLetterC className="header-icon clang" /><b>{course_name}</b></>]
        break;
      case 'web automation':
        text = [<><SiSelenium className="header-icon automation" /><b>{course_name}</b></>]
        break;
      case 'aws cloud':
        text = [<><AiFillAmazonSquare className="header-icon aws" /><b>{course_name}</b></>]
        break;
      case 'reactjs':
        text = [<><BiLogoReact className="header-icon react" /><b>{course_name}</b></>]
        break;
      case 'interview questions':
        text = [<><MdGroup className="header-icon interview" /><b>{course_name}</b></>]
        break;
      case 'coding problems':
        text = [<><RiMiniProgramFill className="header-icon progques" /><b>{course_name}</b></>]
        break;
      case 'courses':
        text = [<><MdMenuBook className="header-icon python" /><b>{course_name}</b></>]
        break;
      case 'quiz':
        text = [<><MdQuestionAnswer className="header-icon javascript" /><b>{course_name}</b></>]
        break;
      default:
        text = [<><FaJava style={{ color: 'red' }} className="header-icon react" /><b>{course_name}</b></>]

    }

    return <Typography variant="h6" sx={{
      display: 'flex', height: '100%', border: '0px solid red', minWidth: 70, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%',
      fontSize: { xs: '10px', sm: '10px', md: '12px', lg: '12px', xl: '15px' }
    }}>{text}</Typography>
  }

  
  function getCourseMenuItem(course_name) {
    let text = ""
    switch (course_name.toLowerCase()) {
      case 'java':
        text = [<div className={courseMenuCss}><FaJava className="header-icon java" /><b>{course_name}</b></div>]
        // text=[<><SvgIcon className="header-icon">{SvgJava}</SvgIcon><b>{course_name}</b></>]
        break;
      case 'home':
        text = [<div className={courseMenuCss}><AiFillHome className="header-icon home" /><b>{course_name}</b></div>]
        break;
      case 'python':
        text = [<div className={courseMenuCss}><BiLogoPython className="header-icon python" /><b>{course_name}</b></div>]
        break;
      case 'javascript':
        text = [<div className={courseMenuCss}><BiLogoJavascript className="header-icon javascript" /><b>{course_name}</b></div>]
        break;
      case 'c':
        text = [<div className={courseMenuCss}><TbCircleLetterC className="header-icon clang" /><b>{course_name}</b></div>]
        break;
      case 'web automation':
        text = [<div className={courseMenuCss}><SiSelenium className="header-icon automation" /><b>{course_name}</b></div>]
        break;
      case 'aws cloud':
        text = [<div className={courseMenuCss}><AiFillAmazonSquare className="header-icon aws" /><b>{course_name}</b></div>]
        break;
      case 'reactjs':
        text = [<div className={courseMenuCss}><BiLogoReact className="header-icon react" /><b>{course_name}</b></div>]
        break;
      case 'interview questions':
        text = [<div className={courseMenuCss}><MdGroup className="header-icon interview" /><b>{course_name}</b></div>]
        break;
      case 'coding problems':
        text = [<div className={courseMenuCss}><RiMiniProgramFill className="header-icon progques" /><b>{course_name}</b></div>]
        break;
      case 'courses':
        text = [<div className={courseMenuCss}><MdMenuBook className="header-icon react" /><b>{course_name}</b></div>]
        break;
      default:
        text = [<div className={courseMenuCss}><FaJava style={{ color: 'red' }} className="header-icon react" /><b>{course_name}</b></div>]

    }

    return <Typography variant="h6" sx={{
      display: 'flex', height: '100%', border: '0px solid red', minWidth: 70, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%',
      fontSize: { xs: '10px', sm: '10px', md: '12px', lg: '12px', xl: '15px' }
    }}>{text}</Typography>
  }


  console.log(mobileOpen)

  return (
    <div>
      <div className="desktop-container">
        <CssBaseline />
        <AppBar component="nav" sx={{ display: 'flex', width: '98vw', position: "relative", background: 'white', m: 0, p: 0, zIndex: 9999999, border: '0px solid yellow', justifyContent: 'space-between' }}>
          <Toolbar sx={{ display: 'flex', width: '98vw', position: "relative", background: 'white', m: 0, p: 0, zIndex: 9999999, border: '0px solid yellow', justifyContent: 'space-around' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              // sx={{ mr: 2, display: { sm: 'flex', md: 'none' }, color: 'black' }}
              className="menu-drawer-icon"
            >
              <MenuIcon color="primary" />
            </IconButton>

            {/* <Box sx={{ border: '0px solid red', display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xm: 'none' }, justifyContent: 'center' }}><img src={LogoSvg} width='70vh' height='auto' style={{ display: 'block' }} /></Box>
          <Box sx={{ border: '0px solid red', marginRight: '25%', display: { xl: 'none', lg: 'none', md: 'none', sm: 'none', xm: 'flex' }, justifyContent: 'center' }}><img src={LogoSvg} width='70vh' height='auto' style={{ display: 'block' }} /></Box> */}
            <div className="cai-logo"><img src={LogoSvg} width='70' height='70' style={{ display: 'block', minHeight: '60px', minWidth: '60px' }} /></div>
            {/* <div sx={{ display: 'flex', width: '100%', border: '0px solid red', alignItems: 'center', height: '100%' }}> */}
            <div className="flex w-full">
              {/* <div sx={{ ml: 5, display: { xl: 'flex', lg: 'flex', md: 'none', sm: 'none' }, minWidth: '80%', border: '0px solid yellow', height: '100%', alignItems: 'center' }}> */}
              {/* <div className="ml-5 hidden xl:flex lg:flex md:flex sm:flex min-w-[70%] h-full items-center space-x-3"> */}
              <div className="header-menu">

                <Link href="/home" key={"home"} className='menu-app-button' underline="none">
                  {getMenuItem("Home")}
                </Link>
                {/* <Link href="/home" key={"home"} className='menu-app-button' underline="none">
                {getMenuItem("Courses")}
              </Link> */}
                <button
                  id="home"
                  className='menu-app-button'
                  aria-controls={openCourse ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openCourse ? 'true' : undefined}
                  onClick={handleClickCourse}
                  key="home"
                >
                  {getMenuItem("Courses")}
                </button>
                <Menu
                  id="course"
                  anchorEl={anchorElCourse}
                  open={openCourse}
                  onClose={handleClose}
                  key="course"
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  className="course-menu"
                >
                  {props.courses.map((item, index) => (
                    (courseList.includes(item.course_name.toLowerCase())) &&
                    <Link href={`/course/${item.course_name}/${encodeValue(item.course_id)}`} key={item.course_name} class='menu-app-list' underline="none" key={item.course_name} id={item.course_id}>
                      {getCourseMenuItem(item.course_name)}
                    </Link>
                  ))}
                  {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                </Menu>
                <Link href={`/course/coding problems/${encodeValue(getCourseID(props.courses, 'Coding Problems'))}`} key={"Coding Problems"} className='menu-app-button' underline="none">
                  {getMenuItem("Coding Problems")}
                </Link>
                <Link href={`/course/interview questions/${encodeValue(getCourseID(props.courses, 'Interview Questions'))}`} key={"Interview Questions"} className='menu-app-button' underline="none">
                  {getMenuItem("Interview Questions")}
                </Link>
                <Link href="/quiz/landing" key={"home"} className='menu-app-button' underline="none">
                  {getMenuItem("Quiz")}
                </Link>
              </div>
              {/* <Box sx={{ ml: 5, display: { xl: 'none', lg: 'none', md: 'none', sm: 'none', xm:"flex" }, width: '80%', border: '1px solid yellow', height: '100%', alignItems: 'center' }}>
              <Link href="/home" key={"home"} className='menu-app-button' underline="none">
                {getMenuItem("Home")}

              </Link>
              {props.courses.map((item, index) => (
                (index <= 3) && <Link href={`/course/${item.course_name}/${item.course_id}`} key={item.course_name} class='menu-app-button' underline="none">
                  {getMenuItem(item.course_name)}
                </Link>
              ))}
              <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <IoIosArrowDropdownCircle className="header-icon more" />
              </IconButton>
              <Menu
                id="basic-menu"
                className="menu-list"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {props.courses.map((item, index) => (
                  (index > 3) && <Link href={`/course/${item.course_name}/${item.course_id}`} key={item.course_name} class='menu-app-button' underline="none">
                    {getMenuItem(item.course_name)}
                  </Link>
                ))}
              </Menu>
            </Box> */}



              <div className="hidden xl:flex lg:flex md:flex justify-center h-full space-x-2 items-center my-auto">
                <a href="https://twitter.com/CodingAcademy1" target="_blank" rel="noopener noreferrer" key={"twitter"} className='social-icon twitter'><FaXTwitter /></a>
                <a href="https://www.youtube.com/channel/UCVVp-72PfILcnKc-E2_2lsQ" target="_blank" rel="noopener noreferrer" key={"youtube"} className='social-icon youtube'><FaSquareYoutube /></a>
                <a href="https://www.facebook.com/profile.php?id=61555136065236" target="_blank" rel="noopener noreferrer" key={"facebook"} className='social-icon facebook'><FaFacebook /></a>
                <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer" key={"linkedin"} className='social-icon linkedin'><FaLinkedin /></a>
                <a href="mailto:info@example.com" key={"email"} className='social-icon email'><AiTwotoneMail /></a>
              </div>


              <Menu
                id="user-menu"
                className="user-list"
                anchorEl={anchorElUser}
                open={openUserMenu}
                onClose={handleClose}
              >
                <button onClick={handleClose} target="_blank" key={"home"} className='w-[150px] p-2 flex flex-row items-center space-x-2 border-b-[1px] border-slate-300' underline="none">
                  <FaUser />
                  <span className="ml-2 text-sm"> Profile</span>

                </button>
                <button onClick={handleClose} key={"home"} className='w-[150px] p-2 flex flex-row items-center space-x-2 border-b-[1px] border-slate-300' underline="none">
                  <CgLogOut />
                  <span className="ml-2 text-sm"> Logout</span>
                </button>
              </Menu>
            </div>
            <div className="w-[25%] absolute right-0 flex justify-end  items-center flex-row">
              {/* <Box sx={{ width: '15%', display: 'flex', justifyContent: 'flex-end', border: '0px solid yellow', height: '100%' }}> */}
              {localStorage.hasOwnProperty("emailId") === false && 
              // <button className="bg-green-700 text-white min-w-[100px] rounded-lg h-8">
                <button className="login-button">
                Log in
              </button>}
              <IconButton size="large" onClick={handleUserMenu}><FaUserCircle className="profile-icon" /></IconButton>
              {/* </Box> */}
            </div>
          </Toolbar>
        </AppBar>
        {/* <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </nav> */}

      </div>
      <div className="mobile-container">
        <CssBaseline />
        <AppBar component="nav" sx={{ display: 'flex', width: '98vw', position: "relative", background: 'white', m: 0, p: 0, zIndex: 9999999, border: '0px solid green', justifyContent: 'space-between' }}>
          {/* <Toolbar sx={{ display: 'flex', width: '98vw', position: "relative", background: 'yellow', m: 0, p: 0, zIndex: 9999999, border: '0px solid yellow', justifyContent: 'space-between' }}> */}
          <Toolbar className="toolbar-mobile">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              // sx={{ mr: 2, display: { sm: 'flex', md: 'none' }, color: 'black' }}
              className="menu-drawer-icon"
            >
              <MenuIcon color="primary" />
            </IconButton>

            <img src={LogoSvg} width='70' height='70' className='cai-image' />
            <div className="flex">


              <Menu
                id="user-menu"
                className="user-list"
                anchorEl={anchorElUser}
                open={openUserMenu}
                onClose={handleClose}
              >
                <button onClick={handleClose} target="_blank" key={"home"} className='w-[150px] p-2 flex flex-row items-center space-x-2 border-b-[1px] border-slate-300' underline="none">
                  <FaUser />
                  <span className="ml-2 text-sm"> Profile</span>

                </button>
                <button onClick={handleClose} key={"home"} className='w-[150px] p-2 flex flex-row items-center space-x-2 border-b-[1px] border-slate-300' underline="none">
                  <CgLogOut />
                  <span className="ml-2 text-sm"> Logout</span>
                </button>
              </Menu>
            </div>
            <div className="w-[25%] absolute right-0 flex justify-end  items-center flex-row">
              {/* <Box sx={{ width: '15%', display: 'flex', justifyContent: 'flex-end', border: '0px solid yellow', height: '100%' }}> */}
              
              <IconButton size="large" onClick={handleUserMenu}><FaUserCircle className="profile-icon" /></IconButton>
              {/* </Box> */}
            </div>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            // sx={{
            //   display: { xs: 'block', sm: 'none' },
            //   '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            // }}
            className={mobileOpen ? "flex" : "hidden"}
          >
            {drawer}
          </Drawer>
        </nav>
      </div>
    </div>
  );
}


export default MenuAppBar;
