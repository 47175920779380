import * as React from 'react';
import { useEffect, useState } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { fetchData, getData, deleteData, putData } from '../../reusables/ApiCalls'
import useApiCall from '../../reusables/useApiCall';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL
export default function BlogList(props) {
  const [open, setOpen] = React.useState(true);
  const { data, loading, error, setRequest } = useApiCall({ url: REACT_APP_API_URL + '/blog/list', method: 'get', payload: null })
  const [blogList, setBlogList] = React.useState(null)
  const { data: dataPost, loading: loadingPost, error: errorPost, setRequest: setRequestPost } = useApiCall({ method: 'post', payload: [], url: '' });
  const { data: dataDelete, loading: loadingDelete, error: errorDelete, setRequest: setRequestDelete } = useApiCall({ method: 'post', payload: [], url: '' });
  const [updated, setUpdated] = React.useState(false)
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e, blog_id) => {
    let blogListCopy = JSON.parse(JSON.stringify(blogList))
    let index = blogListCopy.findIndex(item => item.blog_id === blog_id)
    blogListCopy[index].is_displayed = !blogListCopy[index].is_displayed
    blogListCopy[index].topic_content = JSON.parse(blogListCopy[index].topic_content)
    setBlogList(blogListCopy)
    setRequestPost({ method: 'put', url: REACT_APP_API_URL + '/blog/details', payload: [blogListCopy[index]] })
  };


  const handleBlogAdd = (e) => {
    let payload = []

    let objDict = {}
    objDict.blog_id = null
    objDict.blog_title = "New Blog"
    objDict.topic_content = [{ "sub_topic_name": "sub topic 1", "content": [{ "format": "text", "detail": [{ "type": "text", "content": "Pytest allows user to use markers on the test functions.\nInbuilt markers – xfail,  skip, parameterize.\nUser can create customized markers using below syntax:\n\n\n" }] }] }, { "sub_topic_name": "sub topic 2", "content": [{ "format": "text", "detail": [{ "type": "text", "content": "Pytest allows user to use markers on the test functions.\nInbuilt markers – xfail,  skip, parameterize.\nUser can create customized markers using below syntax:\n\n\n" }] }] }]
    payload.push(objDict)


    if (payload.length !== 0) {
      setRequestPost({ method: 'put', url: REACT_APP_API_URL + '/blog/details', payload: payload })
      setUpdated(!updated)
      setRequest({ url: REACT_APP_API_URL + '/blog/list', method: 'get', payload: null })
      // setMsg("Added !!")

    }

  }

  const handleBlogDelete = (e, blog_id) => {

    setRequestDelete({ method: 'delete', url: REACT_APP_API_URL + '/blog/delete?blog_id=' + blog_id, payload: null })
  }

  useEffect(() => {
    setBlogList(data)
  }
    , [data])

  useEffect(() => {
    setRequest({ url: REACT_APP_API_URL + '/blog/list', method: 'get', payload: null })
  }
    , [dataDelete, dataPost, loadingDelete])

  const handleUpload = (event, blog_id) => {
    let fileName = `/images/${event.target.files[0].name}`
    let blogListCopy = JSON.parse(JSON.stringify(blogList))
    let index = blogListCopy.findIndex(item => item.blog_id === blog_id)
    blogListCopy[index].image_path = fileName
    blogListCopy[index].topic_content = JSON.parse(blogListCopy[index].topic_content)
    setBlogList(blogListCopy)
    setRequestPost({ method: 'put', url: REACT_APP_API_URL + '/blog/details', payload: [blogListCopy[index]] })

  };
  return (
    <div className="bg-slate-100 h-full mt-[100px] border text-center flex justify-center flex-col">
      {(!loading && blogList) && blogList.map((item, index) =>
        <div key={index} className="space-x-2 w-[90%] border flex flex-row p-2 items-center justify-center mx-auto">
          <span className="min-w-[5%] bg-orange-200">{item.blog_id}</span>
          <input
            type="checkbox"
            checked={item.is_displayed}
            className="w-[5%]"
            onChange={(e) => handleCheckboxChange(e, item.blog_id)}
          />
         
          <img src={item.image_path} height="100px" width="100px" />
          
          <span className="min-w-[40%] bg">{item.blog_title}</span>
          <input type="file" onChange={(e) => handleUpload(e, item.blog_id)} id={item.blog_id} name="blog_image" />
          <button className="border bg-green-600 text-white pl-2 pr-2 h-10" onClick={handleBlogAdd}>Add</button>
          <button className="border bg-green-600 text-white pl-2 pr-2 h-10" onClick={(e) => handleBlogDelete(e, item.blog_id)}>Delete</button>

          <a href={`/blog/${item.blog_id}/edit`} target="_blank" 
          className="border bg-green-600 text-white pl-2 pr-2 h-10 text-center flex justify-center items-center">Edit</a>
        </div>
      )}

    </div>
  );
}