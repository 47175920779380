import React, { useEffect, useState } from 'react';
import { Button, TextField, Box, Snackbar, Divider } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { BiSolidMessageRoundedDetail } from 'react-icons/bi'
import useApiCall from '../reusables/useApiCall';
import './Login.css'
import LogoSvg from '../../resources/svg/Color logo - no background.svg'
import Notification from '../../Reusables/Notification';
import { FaRegUserCircle } from "react-icons/fa";
import { DataGrid } from '@mui/x-data-grid';
import { FiLogOut } from "react-icons/fi";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL
const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'category',
        headerName: 'Category',
        width: 150
    },
    {
        field: 'sub_category',
        headerName: 'Sub Category',
        width: 150
    },
    {
        field: 'score',
        headerName: 'Score',
        width: 110
    },
    {
        field: 'created_at',
        headerName: 'Date',
        width: 160
    },
];
const Profile = () => {


    const { data, loading, error, setRequest } = useApiCall({ method: 'get', payload: [], url: `${REACT_APP_API_URL}/users/info?email_id=${localStorage.getItem('emailId')}` });

    const { data: dataQuizLog, loading: loadingQuizLog, error: errorQuizLog, setRequest: setRequestQuizLog } =
        useApiCall({ method: '', payload: [], url: `` });




    function getUserQuizRecord(item, index) {
        return <div className="flex flex-row w-full text-start">
            <span className="min-w-[10%]">{index + 1}</span>
            <span className="min-w-[30%]">{item.category}</span>
            <span className="min-w-[30%]">{item.sub_category}</span>
            <span className="min-w-[10%]">{item.score}</span>
            <span className="min-w-[10%]">{item.created_at}</span>
        </div>

    }
    function getUserQuizRecordHeader() {
        return <div className="flex flex-row w-full text-start font-bold bg-slate-200">
            <span className="min-w-[10%]">S.No.</span>
            <span className="min-w-[30%]">Category</span>
            <span className="min-w-[30%]">Sub Category</span>
            <span className="min-w-[10%]">Score</span>
            <span className="min-w-[10%]">Date</span>

        </div>

    }

    const handleLogout = e=>{
        window.location.href="/login"
    }

    function getUserInfoCard(userData) {
        return (<div className="flex relative flex-col items-center p-4 bg-green-200 h-[40vh] m-2">
            <FaRegUserCircle size={50} />
            <span>{`${userData.first_name} ${userData.last_name}`} </span>
            <span>{`${userData.email_id}`} </span>
            <button className="flex flex-row absolute bottom-0 space-x-2 items-center" onClick={handleLogout}><span>Logout</span> <FiLogOut/></button>
        </div>)
    }


    useEffect(() => {
        if (data && !loading) {
            setRequestQuizLog({ url: `${REACT_APP_API_URL}/quiz/by/user?user_id=${data.user.user_id}`, method:'get' })
        }
    }, [data])

    return (

        <div className="flex flex-row w-full">
            <div className="min-w-[30%] ">
                {loading ? "loading..." : data && getUserInfoCard(data.user)}
            </div>
            <div className="min-w-[60%] border m-2">
                {/* {getUserQuizRecordHeader()} */}
                {/* {loadingQuizLog ? "loading..." : dataQuizLog && dataQuizLog.map((item, index) => getUserQuizRecord(item, index))} */}
                {loadingQuizLog ? "loading..." : dataQuizLog && <DataGrid
                    rows={dataQuizLog}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection={false}
                    disableRowSelectionOnClick
                />}
            </div>

        </div>

    );
};

export default Profile;
