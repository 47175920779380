import useApiCall from '../reusables/useApiCall';
import { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL
const quizRecord = {
    "category": "python",
    "question": "{\"text\":\"\",\"code\":\"\"}",
    "options": "{\"option1\":\"option1\",\"option2\":\"option2\",\"option3\":\"option1\",\"option4\":\"option4\"}",
    "answer": "option2",
    "language": "python",
    "level": 1,
    "createdAt": "2023-11-28 17:31:12",
    "updatedAt": "2023-11-28 17:31:12"
}

export default function Quiz() {
    const { data, loading, error, setRequest } = useApiCall({ url: REACT_APP_API_URL + '/quiz/inventory', method: 'get', payload: null })

    const { data: dataPost, loading: loadingPost, error: errorPost, setRequest: setRequestPost } = useApiCall({ url: '', method: 'put', payload: null });
    const { data: dataCategory, loading: loadingCategory, error: errorCategory, setRequest: setRequestCategory } = useApiCall({ url: REACT_APP_API_URL + '/quiz/category', method: 'get', payload: null });
    const { data: dataLevel, loading: loadingLevel, error: errorLevel, setRequest: setRequestLevel } = useApiCall({ url: REACT_APP_API_URL + '/quiz/level', method: 'get', payload: null });
    const { data: dataCatList, loading: loadingCatList, error: errorCatList, setRequest: setRequestCatList } = useApiCall({ url: REACT_APP_API_URL + '/quiz/category/all', method: 'get', payload: null });

    const [answers, setAnswers] = useState({});
    const [correctAnswers, setCorrectAnswers] = useState({});
    const [quesCount, setQuesCount] = useState(0);
    const [quiz, setQuiz] = useState([]);
    const [selectedCategory, setCategory] = useState("Programming Language");
    const [selectedSubCategory, setSubCategory] = useState([]);

    const handleAnswerChange = (questionId, selectedOption) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: selectedOption,
        }));
    };


    const addQuestion = (event, index) => {
        let quizCopy = JSON.parse(JSON.stringify(quiz))
        quizCopy.splice(index + 1, 0, quizRecord)
        setQuiz(quizCopy)
    }
    const deleteQuestion = (event, index) => {
        let quizCopy = JSON.parse(JSON.stringify(quiz))
        quizCopy.splice(index, 1)
        setQuiz(quizCopy)
    }
    const handleQuestionChange = (event, index, name) => {
        try {
            let quizCopy = JSON.parse(JSON.stringify(quiz))
            let questionJSON = JSON.parse(quizCopy[index].question)
            questionJSON[name] = event.target.value
            quizCopy[index].question = JSON.stringify(questionJSON)
            setQuiz(quizCopy)
        } catch (err) {
            console.log(err)
        }
    }

    const handleOptionChange = (event, index) => {
        try {
            let quizCopy = JSON.parse(JSON.stringify(quiz))
            quizCopy[index].options = event.target.value
            setQuiz(quizCopy)
        } catch (err) {
            console.log(err)
        }

    }

    const handleCorrectAnswer = (event, index) => {
        try {
            let quizCopy = JSON.parse(JSON.stringify(quiz))
            quizCopy[index].answer = event.target.value
            setQuiz(quizCopy)
        } catch (err) {
            console.log(err)
        }

    }

    const handleSave = () => {
        // You can handle the submission of answers here
        let quizCopy = JSON.parse(JSON.stringify(quiz))
        quizCopy.forEach((quiz_item, index) => {
            quiz_item.question = JSON.parse(quiz_item.question)
            quiz_item.options = JSON.parse(quiz_item.options)
        })
        setRequestPost({ url: REACT_APP_API_URL + '/quiz/update', method: 'put', payload: quizCopy })
        // let percent = correctCount * 100 / quesCount
        // alert(`Your Score ${percent}%`);
    };

    const handleCategoryChange = (e, index) => {
        let quizCopy = JSON.parse(JSON.stringify(quiz))
        quizCopy[index]["main_category"] = e.target.value
        let subCategories = dataCategory.filter((item) => item.category === e.target.value)
        setCategory(e.target.value)
        if (subCategories.length > 0) {
            setSubCategory(subCategories[0].sub_category)
            quizCopy[index]["sub_category_options"] = subCategories[0].sub_category
        }
        setQuiz(quizCopy)

    }

    const handleSubCategorySelection = (e, index) => {
        let quizCopy = JSON.parse(JSON.stringify(quiz))
        let options = quizCopy[index]["sub_category_options"]
        quizCopy[index]["category"] = parseInt(e.target.value)
        quizCopy[index]["sub_category"] = options.filter((item) => item.category_id === e.target.value)[0].content

        setQuiz(quizCopy)

    }

    function getQuestionBlock(item, index) {
        try {
            let question = JSON.parse(item.question)
            let options = JSON.parse(item.options)
            let correctAnswer = item.answer
            return (
                // <div className="m-2 bg-slate-100 w-[60%] mx-auto">
                //     <div>{question.text && question.text}</div>
                //     <div>{question.code && question.code}</div>
                //     {Object.keys(options).map((option) => {
                //         return <input type="radio" value={options[option]} />
                //     })}
                // </div>
                <div className="border w-full flex flex-row  m-2 bg-pink-100 items-center justify-center">
                    <span className="m-1">{index + 1}</span>
                    <span className="m-1">{item.ques_id}</span>
                    <div className="w-3/4 flex flex-col">
                        <input className="w-[100%] text-xs border bg-orange-200" value={question.text} name="question" onChange={(e) => handleQuestionChange(e, index, "text")} />
                        <textarea className="w-[100%] text-xs border bg-slate-200" value={question.code} name="question" onChange={(e) => handleQuestionChange(e, index, "code")} />
                        <input className="w-[100%] text-xs border bg-slate-200" value={JSON.stringify(options)} name="options" onChange={(e) => handleOptionChange(e, index)} />
                        <input className="w-[100%] text-xs border bg-slate-200" value={correctAnswer} name="answer" onChange={(e) => handleCorrectAnswer(e, index)} />
                        <select id={`category-${index}`} onChange={(e) => handleCategoryChange(e, index)} value={item.main_category}>
                            {dataCategory.map((item_cat) => <option value={item_cat.category_id} id={item_cat.category_id}>{item_cat.category}</option>)}
                        </select>

                        <select id={`subcategory-${index}`} onChange={(e) => handleSubCategorySelection(e, index)} value={item.category}>
                            {item.sub_category_options.map((item_sub_cat) => <option value={item_sub_cat.category_id} id={item_sub_cat.category_id}>{item_sub_cat.content}</option>)}
                        </select>
                    </div>
                    {/* <div className="w-1/4">
                    {"Correct Answer:" + correctAnswer}
                </div>
                <div className="w-1/4">
                    {answers[item.ques_id] === correctAnswer ? "Right" : "Wrong"}
                </div> */}
                    <div className="flex flex-col bg-orange-200">
                        <IconButton onClick={(e) => addQuestion(e, index)}><AddIcon /></IconButton>
                        <IconButton onClick={(e) => deleteQuestion(e, index)}><DeleteIcon /></IconButton>
                    </div>
                </div>
            )
        } catch (err) {
            console.log(err)
        }
    }

    function getCategoryByCategoryId(category_id) {
        if (dataCatList) {
            let filter = dataCatList.filter(item => item.category_id === category_id)
            if (filter.length > 0) {
                let sub_options = dataCategory.filter(item => item.category === filter[0].category)
                return { category: filter[0].category, sub_category_options: sub_options.length > 0 ? sub_options[0].sub_category : [{ category_id: 1, content: "No Options" }] }
            }
        }
        return { category: "", sub_category_options: [{ category_id: 1, content: "A" }] }
    }

    useEffect(() => {
        if (!loading && data) {
            setQuesCount(data.length)
            let quizCopy = JSON.parse(JSON.stringify(data))
            data.forEach((item, index) =>
                setCorrectAnswers((prevAnswers) => ({
                    ...prevAnswers,
                    [item.ques_id]: item.answer,
                })))

            data.forEach((item, index) => {
                quizCopy[index]["main_category"] = getCategoryByCategoryId(item.category).category
                quizCopy[index]["sub_category_options"] = getCategoryByCategoryId(item.category).sub_category_options

            })
            setQuiz(quizCopy)
        }
    }, [data])


    console.log(data)
    console.log(answers)
    console.log(correctAnswers)

    return (
        <div className="mt-[100px]">
            {quiz && quiz.map((item, index) => {
                return getQuestionBlock(item, index)
            })}
            <button onClick={(e) => handleSave(e)} className="bg-slate-200 p-1">Save Quiz</button>
        </div>
    );
}

