import * as React from 'react';
import { red } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      // main: '#AAFFAA',
      main: '#0000000',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
  typography: {
    subtitle1: {
      fontSize: 20,
    }
  }
});

export default theme;




