import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function QuizDialog(props) {


    return (
        <React.Fragment>
            <Dialog open={props.open} onClose={props.handleClose}>
                <DialogTitle>Are you sure ?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className="mt-10 mb-10 text-lg">
                            {`You still have ${props.summary.pending} questions pending`}
                        </div>

                        <div className="mt-3 mb-3">
                            Click <b>Okay</b> to submit the Quiz and View Result.
                        </div>

                        <div className="mt-3 mb-3">
                            Click <b>Cancel</b> to stay on Quiz Page.
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleSubmit}>Okay</Button>
                    <Button onClick={props.handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
